import { useQuery } from "@tanstack/react-query";

import axiosClient from "../lib/api";

const useCurrentUser = () => {
  const getCurrentUser = async () => {
    const response = await axiosClient.get("/users/me");
      if (response.status === 401) return null;
  
      return response.data;
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ["/users/me"],
    queryFn: getCurrentUser,
  });

  return { user: data, isLoading, isError, isLogin: !!data };
};

export default useCurrentUser;
