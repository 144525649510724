// react-query, axios를 이용한 gql query, mutation을 관리합니다

/**
 * @description linkareer 사용자 조회 query
 */
export const gqlCurrentUserQuery = `
  query CurrentUser($notificationType: NotificationScope) {
    currentUser {
      id
      username
      type
      scrapCount
      notificationUnreadCount(notificationType: $notificationType)
      channel {
        id
        url
        deactivatedAt
      }
      tokenSet {
        accessToken
      }
    }
  }
`;

/**
 * @description linkareer 고객 문의 mutation
 */
export const gqlCreateSuggestionMutation = `
  mutation CreateSuggestion($input: createSuggestionInput!) {
    createSuggestion(input: $input) {
      suggestion {
        email
        content
      }
    }
  }
`;

/**
 * @description linkareer 첨부파일 prepare 업로드 mutation
 */
export const gqlAttachmentPrepareUpload = `
  mutation AttachmentPrepareUpload(
    $name: String!
    $size: Int!
    $type: String!
    $path: String
  ) {
    attachmentPrepareUpload(
      name: $name
      size: $size
      type: $type
      path: $path
    ) {
      attachment {
        id
        url
      }
      signedURL
    }
  }
`;

/**
 * @description linkareer 첨부파일 complete 업로드 mutation
 */
export const gqlAttachmentCompleteUpload = `
  mutation AttachmentCompleteUpload($attachmentID: ID!) {
    attachmentCompleteUpload(attachmentID: $attachmentID) {
      attachment {
        id
        url
        name
        type
        size
      }
    }
  }
`;

/**
 * @description linkareer 자소서 건수 조회 쿼리
 */
export const gqlCoverLetterCountQuery = `
  query CoverLetterTotalCount {
    coverLetterTotalCount
  }
`;
