import { useRouter } from 'next/router';
import { AnchorHTMLAttributes, useState } from 'react';
import { useOpenCustomerMessageForm } from 'src/app.core/linkareer/component/userCustomerService/CustomerMessageForm';
import {
  AD_CONTACT,
  LINKAREER_API_BASE_URL,
  LINKAREER_BIZ_DOMAIN,
  LINKAREER_DOMAIN,
  MEDIA_INTRODUCTION,
} from 'src/app.core/linkareer/constant/urls';
import { CurrentUserQueryResult, UserType } from 'src/app.core/linkareer/types/gql.types';

export enum menuType {
  INTERNAL_LINK = 'internal',
  EXTERNAL_LINK = 'external',
  BUTTON = 'button',
}

type TMenuItem = {
  type: menuType;
  label: string;
  href?: string;
  onClick?: () => void;
  attributes?: AnchorHTMLAttributes<HTMLAnchorElement>;
};

const useUserActionMenu = (currentUser?: CurrentUserQueryResult['currentUser']) => {
  const router = useRouter();
  const openCustomerMessageForm = useOpenCustomerMessageForm();
  const [isUnavailableChannel, setIsUnavailableChannel] = useState(false);

  const onCloseModal = () => {
    setIsUnavailableChannel(false);
  };

  let userMenuList: TMenuItem[] = [];

  if (currentUser?.type === UserType.NORMAL) {
    userMenuList = [
      {
        type: menuType.INTERNAL_LINK,
        label: '담당자 Q&A',
        href: `https://${LINKAREER_DOMAIN}/accounts/reply`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '마이페이지',
        href: `https://${LINKAREER_DOMAIN}/profile`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '나의 시험 CBT',
        href: '/mycbt/archive',
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '스펙 정리하기',
        href: `https://${LINKAREER_DOMAIN}/my-career/spec-management?section=info`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '내가 쓴 채널 댓글',
        href: `https://${LINKAREER_DOMAIN}/accounts/channel-comment`,
      },
      {
        type: menuType.BUTTON,
        label: '고객문의',
        onClick: () => {
          openCustomerMessageForm();
        },
      },
      {
        type: menuType.INTERNAL_LINK,
        href: `${LINKAREER_API_BASE_URL}/logout?next=https://${LINKAREER_DOMAIN}`,
        label: '로그아웃',
      },
    ];
  } else {
    userMenuList = [
      {
        type: menuType.EXTERNAL_LINK,
        label: '공고 등록',
        href: `${LINKAREER_BIZ_DOMAIN}/register/activity`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '공고/지원자 관리',
        href: `${LINKAREER_BIZ_DOMAIN}/activities/ALL`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '회원/기업정보 관리',
        href: `${LINKAREER_BIZ_DOMAIN}/profile`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        href: `${LINKAREER_BIZ_DOMAIN}/channel/written-comment`,
        label: '내가 쓴 채널 댓글',
      },
      {
        type: menuType.BUTTON,
        label: '채널 관리',
        onClick: () => {
          if (!currentUser?.channel || currentUser.channel.deactivatedAt) {
            return setIsUnavailableChannel(true);
          }
          router.push(`${LINKAREER_BIZ_DOMAIN}/channel`);
        },
      },
      {
        type: menuType.BUTTON,
        label: '내 채널 바로가기',
        onClick: () => {
          if (!currentUser?.channel || currentUser.channel.deactivatedAt) {
            return setIsUnavailableChannel(true);
          }
          router.push(`https://${LINKAREER_DOMAIN}/channel/${currentUser.channel?.url}`);
        },
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '광고 제휴문의',
        href: AD_CONTACT,
        attributes: { target: '_blank' },
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '매체소개서 다운로드',
        href: MEDIA_INTRODUCTION,
        attributes: {
          target: '_blank',
          download: true,
        },
      },
      {
        type: menuType.EXTERNAL_LINK,
        href: `${LINKAREER_API_BASE_URL}/logout?next=https://${LINKAREER_DOMAIN}`,
        label: '로그아웃',
      },
    ];
  }

  return {
    userMenuList,
    channelUnavailable: isUnavailableChannel,
    onCloseModal,
  };
};

export default useUserActionMenu;
