const size = {
  mobile: '320px',
  tablet: '768px',
  tabletLandscape: '1024px',
  desktop: '1280px',
};

export const theme = {
  device: {
    //mobile: `(min-width: ${size.mobile}) and (max-width: ${size.tablet})`,
    mobile: `(max-width: ${size.tablet})`,
    tablet: `(min-width: ${size.tablet}) and (max-width: ${size.tabletLandscape})`,
    tabletLandscape: `(min-width: ${size.tabletLandscape}) and (max-width: ${size.desktop})`,
    desktop: `(min-width: ${size.desktop})`,
  },
};

export const color = {};
