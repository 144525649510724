import { useEffect, useState } from 'react';
import { uaIsMobile } from '../module/uaIsMobile';

type UseIsUaMobileHook = () => {
  isMobile: boolean | null;
};

/**
 * @desc UserAgent를 통해 모바일 여부를 판단합니다
 * @returns isMobile
 */
export const useIsUaMobile: UseIsUaMobileHook = () => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null);

  useEffect(() => {
    if (navigator) {
      const uaMobile = uaIsMobile(navigator.userAgent);
      setIsMobile(() => uaMobile);
    }
  }, []);

  return { isMobile };
};
