import { FC } from 'react'
import styled from 'styled-components'

const NewBadge: FC = () => {
  return (
    <StyledWrapper>
      <div className="new-badge" />
    </StyledWrapper>
  )
}

export default NewBadge

const StyledWrapper = styled.div`
  position: relative;
  .new-badge {
    position: absolute;
    width: 6px;
    height: 6px;
    top: -12px;
    right: -12px;
    border-radius: 6px;
    background-color: #ef2929;
  }
`
