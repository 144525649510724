import axios, { CreateAxiosDefaults } from 'axios';
import { addAccessTokenToHeader } from './interceptors/addAccessTokenToHeader.interceptor';
import { addBaseUrlByHostInterceptor } from './interceptors/addBaseUrlByHost.interceptors';

const axiosDefaultOptions: CreateAxiosDefaults = {
  withCredentials: true,
  timeout: 1000 * 10,
};

export const axiosClient = axios.create(axiosDefaultOptions);

axiosClient.interceptors.request.use(addAccessTokenToHeader);
axiosClient.interceptors.request.use(addBaseUrlByHostInterceptor);

axiosClient.interceptors.response.use();
