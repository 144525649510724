import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { useEffect, useState } from 'react';
import { getCookieDomain } from 'src/app.core/linkareer/module/cookie';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const PROMOTION_POPUP_COOKIE = 'promotion-popup';

const includeUrls = ['/', /exam\/detail/];

export const usePromotionPopup = () => {
  const [isOpenPromotionPopup, setIsOpenPromotionPopup] = useState<boolean>(false);
  const router = useRouter();
  const { pathname } = router;

  const handleClickClose = () => {
    setIsOpenPromotionPopup(() => false);
  };

  const handleClickTodayClose = () => {
    const oneDay = dayjs().add(1, 'minute').toDate();

    nookies.set(null, PROMOTION_POPUP_COOKIE, Date.now().toString(), {
      expires: oneDay,
      domain: getCookieDomain(),
      path: '/',
    });
    setIsOpenPromotionPopup(() => false);
  };

  const handleRouteUrl = (url: string) => {
    router.push(url);
  };

  useEffect(() => {
    const today = dayjs().toDate();
    const expireDate = dayjs(new Date(2024, 9, 27, 23, 59, 59)).toDate();
    const isBefore = dayjs(today).isBefore(expireDate);
    const isInclude = includeUrls.some((url) => {
      if (typeof url === 'string') {
        return pathname === url;
      }
      return url instanceof RegExp && url.test(pathname);
    });

    if (isInclude && isBefore) {
      const cookies = nookies.get(null);
      setIsOpenPromotionPopup(!cookies[PROMOTION_POPUP_COOKIE]);
    }
  }, []);

  return {
    isOpenPromotionPopup,
    handleRouteUrl,
    handleClickClose,
    handleClickTodayClose,
  };
};
