/**
 * @nurseEdu
 * 널스에듀 헤더의 상단의 네비게이션 바를 위한 코드입니다.
 */

import Link from 'next/link';
import { NURSEEDU_BASE_URL } from 'src/app.core/nurseEdu/constant/nurseEduConfig';
import { logout } from 'src/app.core/nurseEdu/lib/logout';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';
import styled from 'styled-components';

const HeaderUserNav = () => {
  const { user } = useCurrentUser();

  

  return (
    <StyledContainer>
      <div>
        <nav>
          {!!user ? (
            <>
              <button onClick={() => logout()}>로그아웃</button>
              <Link href={`${NURSEEDU_BASE_URL}/mypage`}>마이페이지</Link>
            </>
          ) : (
            <>
              <Link href={`${NURSEEDU_BASE_URL}/register`}>회원가입</Link>
              <Link href={`${NURSEEDU_BASE_URL}/login`}>로그인</Link>
            </>
          )}
          <Link href={`${NURSEEDU_BASE_URL}/help`}>고객센터</Link>
        </nav>
      </div>
    </StyledContainer>
  );
};

export default HeaderUserNav;

const StyledContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  min-width: 1220px;
  background-color: #f5f5f5;

  > div {
    width: 100%;
    min-width: 1200px;
  }

  nav {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding-right: 10px;
  }

  a,
  button {
    padding: 7px 12px;
    color: #222;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: -0.025em;
    background-color: transparent;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
