import { PageProps } from 'pages/_app';
import { MutableSnapshot, RecoilState } from 'recoil';
import { setHostState } from 'recoil/Academy/hostState';

interface DehydratedRecoilState {
  function: RecoilState<any>;
  state: any;
}
/**
 * Recoil SSR용 Hooks 입니다.
 * @param CbtAppProps App Component의 CbtAppProps
 */
export default function useDehydratedRecoilState(pageProps: PageProps) {
  /**
   * @param function SetRecoilState에 해당하는 함수를 넣습니다
   * @param state function에 해당하는 state를 넣어 초기값을 만듭니다
   */
  const dehydrateRecoilStateMap: DehydratedRecoilState[] = [
    {
      function: setHostState,
      state: pageProps.host,
    },
  ];

  /**
   *  state가 없다면 reset을 하여 default 값을, state가 있다면 해당 값을 저장합니다.
   */
  const dehydratedRecoilState = ({ set, reset }: MutableSnapshot) => {
    return dehydrateRecoilStateMap.map((dehydrated) =>
      dehydrated.state === undefined || dehydrated.state === null
        ? reset(dehydrated.function)
        : set(dehydrated.function, dehydrated.state)
    );
  };
  return dehydratedRecoilState;
}
