import { TExamsInfo } from 'app.type/examsInfo';
import { DefaultSeoProps, NextSeoProps } from 'next-seo';
import { BaseSeoConfig } from 'app.core/common/component/seo/BaseSeoConfig';

export class NurseEduSeoConfig extends BaseSeoConfig {
  private static createSeoTemplate({
    title,
    description,
    url,
  }: {
    title: string;
    description: string;
    url: string;
  }): NextSeoProps {
    return {
      title,
      description,
      openGraph: {
        type: 'website',
        url,
        title,
        description,
        images: [
          {
            url: '/images/nurseEdu/og_nurseEdu.png',
            width: 1200,
            height: 630,
            alt: '널스에듀 CBT',
          },
        ],
      },
      additionalMetaTags: [
        { httpEquiv: 'content-type', content: 'text/html' },
        { property: 'article:publisher', content: 'https://cbt.nurse-edu.co.kr/' },
        // { property: 'fb:app_id', content: '265463423907972' },
        // { property: 'fb:pages', content: '1715100552083537' },
        { name: 'application-name', content: '널스에듀' },
        { name: 'apple-mobile-web-app-title', content: '널스에듀' },
        { name: 'theme-color', content: '#38459f' },
        { name: 'naver-site-verification', content: 'f0b4f3713b376e66d3f49d2e56b3f81d67c5b2f1' },
        { name: 'google-site-verification', content: 'Dh6jOZE13Y2jnPk-5AdU21-9ulTqsygEatjzRDO7DWI' },
        { name: 'twitter:image', content: '/images/nurseEdu/og_nurseEdu.png' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description',content: description },
      ],
    };
  }

  getDefaultSeoMessage(data?: unknown): DefaultSeoProps {
    return {
      title: "널스에듀 CBT | 간호사 취업, 간호사 시험, 면접 대비",
      description:
        "간호사 취업 CBT 모의고사와 자기소개서, 면접 대비 강의로 간호사 시험, 병원 합격을 준비하세요. 간호학과 과제, 레포트도 전문적으로 지원합니다",
      canonical: "https://cbt.nurseedu.com/",
      openGraph: {
        type: "website",
        url: "https://cbt.nurse-edu.co.kr/",
        title: "널스에듀 CBT | 간호사 취업, 간호사 시험, 면접 대비",
        description:
          "간호사 취업 CBT 모의고사와 자기소개서, 면접 대비 강의로 간호사 시험 합격을 준비하세요. 간호학과 과제, 레포트도 전문적으로 지원합니다",
        images: [
          {
            url: "/images/nurseedu/og_nurseedu.png",
            width: 1200,
            height: 630,
            alt: "널스에듀 CBT",
          },
        ],
      },
      additionalMetaTags: [
        { httpEquiv: "content-type", content: "text/html" },
        { property: "article:publisher", content: "https://cbt.nurse-edu.co.kr/" },
        { name: "application-name", content: "널스에듀" },
        { name: "apple-mobile-web-app-title", content: "널스에듀" },
        { name: "theme-color", content: "#01a0ff" },
      ],
      additionalLinkTags: [
        {
          rel: "apple-touch-icon",
          href: "/images/nurseEdu/ic-launcher-192.png",
        },
        {
          rel: "shortcut icon",
          type: "image/x-icon",
          href: "/images/nurseEdu/favicon.ico",
        },
      ],
    };
  }

  getExamListSeoMessage(type?: string): NextSeoProps {
    const seoMapping: Record<string, { title: string; description: string }> = {
      //TODO: 추후 key value 확인 및 수정 필요
      "hospital-test": {
        title: "간호사 시험 필기시험 대비 | 간호사 시험 모의고사 - 널스에듀",
        description:
          "널스에듀에서 간호사 시험 필기시험 대비 CBT 모의고사를 실전처럼 풀어보세요. 삼성서울병원, 서울아산병원, 세브란스병원 등 맞춤형 문제 제공으로 취업 성공을 준비하세요.",
      },
      "interview-prep": {
        title: "간호사 면접 대비 | 실전 질문과 답변 전략 - 널스에듀",
        description:
          "널스에듀에서 간호사 면접을 철저히 대비하세요. 간호사 시험 합격자들의 실제 질문 사례와 상세한 답변 전략으로 면접 합격을 목표로 준비하세요.",
      },
      "academic-test": {
        title: "간호학과 과제 & 필기시험 대비 | 널스에듀",
        description:
          "널스에듀에서 간호학과 전공 시험과 과제를 효과적으로 준비하세요. 전문 자료와 모의 문제를 통해 학습 능력을 키우고 학업 성취를 높이세요.",
      },
    };
  
    const seoData = seoMapping[type as string] || {
      title: "간호사 CBT | 필기시험, 면접 대비, 과제 준비 - 널스에듀",
      description:
        "널스에듀에서 간호사 취업 CBT 모의고사와 면접 대비 강의를 만나보세요. 검증된 간호사 취업 전문가와 시험 대비 문제로 효과적인 학습을 경험하세요!",
    };
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title: seoData.title,
        description: seoData.description,
        url: `https://cbt.nurse-edu.co.kr/exam/${type}`,
      }),
    };
  }

  getExamDetailSeoMessage(data: TExamsInfo): NextSeoProps {
    const examType = (data?.examTypes || []).filter(
      (type: { parentId: number | null; name: string }) => !type?.parentId
    );
    const examTypeName = String(examType[0]?.name || "");
  
    const seoMapping: Record<string, { title: string; description: string }> = {
      //TODO: 추후 key value 확인 및 수정 필요
      "간호사 시험": {
        title: `간호사 시험 필기시험 준비 | 삼성서울·아산병원·세브란스 문제 풀이 - 널스에듀`,
        description:
          "널스에듀에서 삼성서울병원, 서울아산병원, 세브란스병원 필기시험 대비 CBT 모의고사를 준비하세요. 최신 기출 문제와 출제 경향을 반영한 학습 자료로 간호사 시험 취업을 목표로 실력을 키우세요.",
      },
      "면접대비": {
        title: `간호사 면접 대비 | 간호사 시험 맞춤형 질문 사례와 답변 전략 - 널스에듀`,
        description:
          "널스에듀에서 간호사 면접 대비를 위한 맞춤형 질문 사례와 상세한 답변 전략을 제공합니다. 간호사 시험 면접을 철저히 준비하세요.",
      },
      "전공시험": {
        title: `간호학과 전공 필기시험 준비 | 과제 해결과 실전 학습 - 널스에듀`,
        description:
          "널스에듀에서 간호학과 전공 필기시험과 과제를 완벽히 준비하세요. 전공별 실전 문제와 해설, 학습 자료로 학업 성취를 높이세요.",
      },
    };
  
    const seoData = seoMapping[examTypeName as string] || {
      title: "간호사 CBT | 필기시험, 면접 대비, 전공 학습 - 널스에듀",
      description:
        "널스에듀에서 간호사 CBT 모의고사와 면접 대비 강의를 만나보세요. 간호학과 전공 필기시험과 과제 준비까지 모두 지원합니다!",
    };
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title: seoData.title,
        description: seoData.description,
        url: `https://cbt.nurse-edu.co.kr/exam/${data.examIdx}`,
      }),
    };
  }

  getExamTypeSelectionSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = "CBT 시험 풀이 방식 선택 | 간호사 시험, 간호학과 시험 준비 - 널스에듀";
    const description =
      "널스에듀 CBT에서 한 문제씩 푸는 학습 모드 또는 실전처럼 풀어보는 모의고사 모드를 선택하세요. 간호사 시험과 전공 학습 준비에 최적화된 학습 환경을 제공합니다.";
    const url = `https://cbt.nurse-edu.co.kr/cbt/select/${examDetailIdx}`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getCbtOneQuestionTypeSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = "한 문제씩 풀며 집중 학습 | 간호사 CBT 시험 대비 - 널스에듀";
    const description =
      "널스에듀의 한 문제씩 풀기 모드로 간호사 필기시험을 단계별로 학습하세요. 실력을 키우는 효율적인 학습으로 간호사 시험 취업을 준비하세요.";
    const url = `https://cbt.nurse-edu.co.kr/cbt/onequestions/${examDetailIdx}`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getCbtPracticeTypeSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = "CBT 모의고사로 실전 연습 | 간호사 시험 간호사 필기시험 대비 - 널스에듀";
    const description =
      "널스에듀 CBT 모의고사 모드에서 실제 시험 환경과 동일하게 간호사 필기시험을 대비하세요. 실전 감각을 기르고 자신감을 키우세요.";
    const url = `https://cbt.nurse-edu.co.kr/cbt/practice/${examDetailIdx}`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getExamResultSeoMessage(recordIdx: string): NextSeoProps {
    const title = "CBT 시험 결과 분석 | 간호사 필기시험 피드백 제공 - 널스에듀";
    const description =
      "널스에듀 CBT에서 시험 결과를 확인하고, 세부 성적 분석과 피드백으로 실력을 진단하세요. 간호사 취업 시험 대비를 위해 맞춤형 학습 계획을 세우세요.";
    const url = `https://cbt.nurse-edu.co.kr/cbt/practice/score/${recordIdx}`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getExamSolutionSeoMessage(recordIdx: string): NextSeoProps {
    const title = "CBT 시험 해설 및 문제 분석 | 널스에듀 간호사 취업 준비";
    const description = "널스에듀 CBT에서 시험 문제의 정답과 해설을 확인하세요. 상세한 문제 분석으로 간호사 취업 시험 대비 실력을 향상시키세요.";
    const url = `https://cbt.nurse-edu.co.kr/cbt/practice/score/${recordIdx}`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTArchiveSeoMessage(): NextSeoProps {
    const title = "나의 관심 시험 기록 | 널스에듀 CBT 맞춤형 학습";
    const description =
      "널스에듀 '나의 관심 시험' 페이지에서 간호사 취업 시험과 과제를 관리하고, 맞춤형 학습 기록을 확인하세요. 효과적인 학습 계획으로 시험 준비를 완벽하게 대비하세요.";
    const url = `https://cbt.nurse-edu.co.kr/mycbt/archive`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTResultSeoMessage(): NextSeoProps {
    const title = "CBT 결과보기 | 널스에듀 시험 성적 분석 및 피드백";
    const description =
      "널스에듀 'CBT 결과보기'에서 간호사 필기시험과 과제 성적을 확인하세요. 상세한 분석과 피드백으로 학습 성과를 높이고 취업 성공 가능성을 강화하세요.";
    const url = `https://cbt.nurse-edu.co.kr/mycbt/review`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTNoteSeoMessage(): NextSeoProps {
    const title = "오답노트 | 널스에듀 CBT 틀린 문제 복습 및 재학습";
    const description =
      "널스에듀 '오답노트'에서 틀린 문제를 다시 풀고 복습하세요. 간호사 취업 시험 대비를 위한 재학습으로 취약점을 보완하고 실력을 강화하세요.";
    const url = `https://cbt.nurse-edu.co.kr/mycbt/note`;
  
    return {
      ...NurseEduSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }
}
