import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

interface ChattingIconProps extends TSvgProps {
  isActive: boolean;
}

const ChattingIcon: FC<ChattingIconProps> = ({ isActive, ...props }) => {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="icon / 24 / chatting"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>채팅 아이콘 활성화</desc>
      <path fill="none" d="M0 0h24v24H0z" data-name="사각형 10239"></path>
      <g fill="#333" data-name="그룹 19237" transform="translate(2882.018 18965.922)">
        <circle
          cx="7"
          cy="7"
          r="7"
          data-name="타원 1513"
          transform="translate(-2879 -18963)"
        ></circle>
        <path d="M-2878.584-18950.38l.877-3.742 2.872 3.096z" data-name="패스 8958"></path>
      </g>
      <path
        fill="#333"
        stroke="#333"
        strokeWidth="0.3"
        d="M10.032 16.982a6.792 6.792 0 10-6.228-4.067l.156.356-.54 2.289 1.959-.39.422.336a6.726 6.726 0 004.231 1.476m0 1.162a7.928 7.928 0 01-4.955-1.729l-2.02.4a.775.775 0 01-.906-.937l.589-2.5a7.962 7.962 0 117.292 4.761z"
        data-name="합치기 198"
      ></path>
      <path
        fill="#fff"
        d="M14.031 5.621a7.938 7.938 0 000 15.874 7.778 7.778 0 003.88-1.036l.412-.238 2.931.484-.72-2.667.283-.491a8 8 0 001.063-3.989 7.9 7.9 0 00-7.849-7.937m0-1.357a9.316 9.316 0 017.947 13.965l.755 2.8a.9.9 0 01-1.007 1.131l-3.15-.52a9.068 9.068 0 01-4.545 1.214 9.243 9.243 0 01-9.191-9.296 9.243 9.243 0 019.191-9.294z"
        data-name="합치기 199 - 윤곽선"
      ></path>
      <g data-name="그룹 43574" transform="translate(2856.018 18965.922)">
        <circle
          cx="7"
          cy="7"
          r="7"
          fill="#333"
          data-name="타원 1512"
          transform="translate(-2848.875 -18959.299)"
        ></circle>
        <path
          fill="#333"
          d="M-2834.998-18945.47l-4.152-1.504 2.648-2.649z"
          data-name="다각형 49"
        ></path>
        <path
          fill="#333"
          stroke="#333"
          strokeWidth="0.3"
          d="M-2841.745-18959.059a6.8 6.8 0 103.361 12.709l.357-.2 2.539.414-.618-2.285.245-.421a6.8 6.8 0 00-5.884-10.217m0-1.162a7.963 7.963 0 016.884 11.962l.654 2.4a.775.775 0 01-.872.969l-2.727-.45a7.961 7.961 0 11-3.939-14.881z"
          data-name="합치기 199 - 윤곽선"
        ></path>
        <rect
          width="8"
          height="1.5"
          fill="#fff"
          data-name="사각형 30317"
          rx="0.75"
          transform="translate(-2845.518 -18953.822)"
        ></rect>
        <rect
          width="5"
          height="1.5"
          fill="#fff"
          data-name="사각형 30318"
          rx="0.75"
          transform="translate(-2845.518 -18951.223)"
        ></rect>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="icon / 24 / chatting"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>채팅 아이콘 비활성화</desc>
      <path fill="none" d="M0 0h24v24H0z" data-name="사각형 10239"></path>
      <g fill="#fff" data-name="그룹 19237" transform="translate(2882.018 18965.922)">
        <circle
          cx="7"
          cy="7"
          r="7"
          data-name="타원 1513"
          transform="translate(-2879 -18963)"
        ></circle>
        <path d="M-2878.584-18950.38l.877-3.742 2.872 3.096z" data-name="패스 8958"></path>
      </g>
      <path
        fill="#888"
        stroke="#888"
        strokeWidth="0.3"
        d="M10.032 16.982a6.792 6.792 0 10-6.228-4.067l.156.356-.54 2.289 1.959-.39.422.336a6.726 6.726 0 004.231 1.476m0 1.162a7.928 7.928 0 01-4.955-1.729l-2.02.4a.775.775 0 01-.906-.937l.589-2.5a7.962 7.962 0 117.292 4.761z"
        data-name="합치기 198"
      ></path>
      <g data-name="그룹 19238" transform="translate(2856.018 18965.922)">
        <circle
          cx="7"
          cy="7"
          r="7"
          fill="#fff"
          data-name="타원 1512"
          transform="translate(-2848.875 -18959.299)"
        ></circle>
        <path
          fill="#fff"
          d="M-2834.998-18945.47l-4.152-1.504 2.648-2.649z"
          data-name="다각형 49"
        ></path>
        <path
          fill="#888"
          stroke="#888"
          strokeWidth="0.3"
          d="M-2841.745-18959.059a6.8 6.8 0 103.361 12.709l.357-.2 2.539.414-.618-2.285.245-.421a6.8 6.8 0 00-5.884-10.217m0-1.162a7.963 7.963 0 016.884 11.962l.654 2.4a.775.775 0 01-.872.969l-2.727-.45a7.961 7.961 0 11-3.939-14.881z"
          data-name="합치기 199 - 윤곽선"
        ></path>
        <rect
          width="8"
          height="1.5"
          fill="#888"
          data-name="사각형 30317"
          rx="0.75"
          transform="translate(-2845.518 -18953.822)"
        ></rect>
        <rect
          width="5"
          height="1.5"
          fill="#888"
          data-name="사각형 30318"
          rx="0.75"
          transform="translate(-2845.518 -18951.223)"
        ></rect>
      </g>
    </svg>
  );
};

export default ChattingIcon;
