import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import CustomerService from './customerService.form/CustomerService';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { CurrentUserQueryResult } from '../../types/gql.types';

const CustomerMessageFormContext = createContext<any>(null);

export const useOpenCustomerMessageForm = () => {
  return useContext(CustomerMessageFormContext);
};

interface CustomerMessageFormProps {
  open: boolean;
  currentUser?: CurrentUserQueryResult['currentUser'];
  onRequestClose: () => any;
}

interface CustomerMessageFormContainerProps {
  children: ReactNode;
}

const CustomerMessageForm: FC<CustomerMessageFormProps> = ({
  currentUser,
  open,
  onRequestClose,
}) => {
  if (!open) {
    return null;
  }

  return <CustomerService currentUser={currentUser} onRequestClose={onRequestClose} />;
};

const CustomerMessageFormContainer: FC<CustomerMessageFormContainerProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <CustomerMessageFormContext.Provider value={handleOpen}>
        {children}
      </CustomerMessageFormContext.Provider>
      <CustomerMessageForm open={open} currentUser={currentUser} onRequestClose={handleClose} />
    </>
  );
};

export default CustomerMessageFormContainer;
