import { useRouter } from 'next/router';

const MY_CBT_PATH = ['/mycbt/archive', '/mycbt/note', '/mycbt/review'];

const HIDE_PAGE_LIST = [...MY_CBT_PATH, /exam\/detail/];

const checkHideInPage = (pathname: string): boolean => {
  return HIDE_PAGE_LIST.some((path) => {
    if (typeof path === 'string') {
      return pathname === path;
    }
    return path instanceof RegExp && path.test(pathname);
  });
};

const checkIsMyCbtPage = (pathname: string): boolean => {
  return MY_CBT_PATH.includes(pathname);
};

/**
 * @description 특정 페이지 진입여부와 숨김 처리를 할지 여부를 반환합니다.
 * @returns
 */
export const useCheckHidePage = () => {
  const { pathname } = useRouter();

  return {
    isHideInPage: checkHideInPage(pathname),
    isMyCbtPage: checkIsMyCbtPage(pathname),
  };
};
