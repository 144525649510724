import Link, { LinkProps } from 'next/link';
import { FC, Fragment, MouseEvent, useState } from 'react';
import CareerPageNavLinkIcon from 'src/app.core/linkareer/svgIcon/CareerPageNavLinkIcon';
import { UserType } from 'src/app.core/linkareer/types/gql.types';
import styled from 'styled-components';
import { UrlObject } from 'url';
import NotManagerModal from './NotManagerModal';

interface IFProps extends Omit<LinkProps, 'href'> {
  activityHref: UrlObject;
  activityLabel: string;
  userType?: UserType;
}

const SpecManageButton: FC<IFProps> = ({
  activityHref,
  activityLabel,
  userType,
  ...restLinkProps
}) => {
  const [showManagerAlert, setShowManagerAlert] = useState<boolean>(false);

  const handleClickManagerAlert = (e: MouseEvent<HTMLAnchorElement>) => {
    if (userType && userType !== UserType.NORMAL) {
      setShowManagerAlert(true);
      e.preventDefault();
    }
  };

  const handleCloseModal = () => {
    setShowManagerAlert(false);
  };

  return (
    <Fragment>
      <Link href={activityHref} onClick={handleClickManagerAlert} {...restLinkProps}>
        <StyledWrapper>
          <CareerPageNavLinkIcon width={20} height={20} />
          {activityLabel}
        </StyledWrapper>
      </Link>
      {showManagerAlert && <NotManagerModal onClose={handleCloseModal} />}
    </Fragment>
  );
};

export default SpecManageButton;

const StyledWrapper = styled.p`
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.64px;
  text-align: left;
  color: #1530ff;

  &:hover {
    color: #1530ff; // 전역 스타일 무시
  }
`;
