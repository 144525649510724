import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { LINKAREER_COMMUNITY_DOMAIN, LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import styled from 'styled-components';
import ChatAppModal from '../../chat/component/ChatAppModal';
import getTabBarData from './components/getTabBarData';
import TabBarItem from './TabBarItem';

interface TabBarMobileProps {}

const TabBarMobile: FC<TabBarMobileProps> = () => {
  const router = useRouter();
  const [isOpenChatAppModal, setIsOpenChatAppModal] = useState<boolean>(false);
  const { calender, chatting, community, home, menu } = getTabBarData({
    activedTabPathname: router.pathname,
  });

  const handleOpenChatAppModal = () => {
    setIsOpenChatAppModal(true);
  };

  const handleCloseChatAppModal = () => {
    setIsOpenChatAppModal(false);
  };

  return (
    <StyledWrapper>
      <Link href={`https://${LINKAREER_DOMAIN}/nav`} className="tab-bar-menu">
        <TabBarItem {...menu} />
      </Link>
      <Link href={`https://${LINKAREER_COMMUNITY_DOMAIN}/community`} className="tab-bar-menu">
        <TabBarItem {...community} />
      </Link>
      <Link href={`https://${LINKAREER_DOMAIN}`} className="tab-bar-menu">
        <TabBarItem {...home} />
      </Link>
      <Link href={`https://${LINKAREER_DOMAIN}/calendar`} className="tab-bar-menu">
        <TabBarItem {...calender} />
      </Link>
      <TabBarItem {...chatting} onClick={handleOpenChatAppModal} className="tab-bar-menu" />
      {isOpenChatAppModal && <ChatAppModal onClickClose={handleCloseChatAppModal} />}
    </StyledWrapper>
  );
};

export default TabBarMobile;

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 29;
  width: 100%;
  height: 54px;
  background-color: #fff;
  border-top: 1px solid #eeeeee;
  display: flex;

  .tab-bar-menu {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
