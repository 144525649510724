import React from 'react';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from '../../../recoil/Academy/projectConfigState';

const Logo: React.FC = () => {
  const { clientBaseUrl, logo, projectName } = useRecoilValue(projectConfigState);

  return (
    <Link href={clientBaseUrl ? clientBaseUrl : '/'}>
      <img className="logo" src={logo} alt={projectName ? projectName : 'ALT_TEXT_SERVICE_LOGO'} />
    </Link>
  );
};

export default Logo;
