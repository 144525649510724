import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

interface HomeIconProps extends TSvgProps {
  isActive: boolean;
}

const HomeIcon: FC<HomeIconProps> = ({ isActive, ...props }) => {
  return isActive ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <desc>홈 아이콘 활성화</desc>
      <defs>
        <clipPath id="8miv7dgt2a">
          <path fill="none" d="M0 0h24v24H0z" data-name="사각형 6266"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#8miv7dgt2a)" data-name="그룹 8626">
        <path
          fill="#333"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M1.834 12.469l9.206-9.478a1.34 1.34 0 011.92 0l9.207 9.478a.2.2 0 01-.146.343h-2.546V20.9h-5.041v-5.345a.838.838 0 00-.84-.838H10.4a.839.839 0 00-.839.838V20.9H4.523v-8.09H1.979a.2.2 0 01-.145-.341z"
          data-name="패스 5058"
        ></path>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <desc>홈 아이콘 비활성화</desc>
      <defs>
        <clipPath id="hllbt09iya">
          <path fill="none" d="M0 0h24v24H0z" data-name="사각형 6266"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#hllbt09iya)" data-name="그룹 8626">
        <path
          fill="none"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M1.834 12.725l9.206-9.478a1.34 1.34 0 011.92 0l9.207 9.478a.2.2 0 01-.146.343h-2.546v8.088h-5.041v-5.345a.838.838 0 00-.84-.838H10.4a.839.839 0 00-.839.838v5.345H4.523v-8.09H1.979a.2.2 0 01-.145-.341z"
          data-name="패스 5058"
        ></path>
      </g>
    </svg>
  );
};

export default HomeIcon;
