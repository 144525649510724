import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import MagnifyingGlassIcon from 'src/app.core/linkareer/svgIcon/MagnifyingGlassIcon';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';

type TForm = {
  search: string;
};

const SearchBarMobile: FC = () => {
  const router = useRouter();
  const { register, handleSubmit } = useForm<TForm>();

  const onSubmit = (data: TForm) => {
    if (!data.search) return;

    router.push({
      pathname: `https://${LINKAREER_DOMAIN}/search`,
      query: {
        ...router.query,
        q: data.search,
        p: 1,
      },
    });
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <button type="submit" className="search-icon-btn">
          <MagnifyingGlassIcon />
        </button>
        <input
          id="header-search"
          className="search-input"
          type="search"
          placeholder="대외활동, 인턴, 채용 정보 검색"
          defaultValue={router.query.q || ''}
          {...register('search')}
        />
      </form>
    </StyledWrapper>
  );
};

export default SearchBarMobile;

const StyledWrapper = styled.div`
  padding: 10px 14px;

  .form {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 7px;

    input {
      width: 100%;
      font-size: 16px;
      height: 21px;
      background-color: #ffffff;
      line-height: 21px;
      border: none;
      outline: none;
      -webkit-appearance: textfield; // ios 에서 input type search일 경우 나타나는 돋보기 스타일 제거
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    .search-icon-btn {
      display: flex;
      border: none;
      background-color: transparent;
      padding: 1px 6px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
