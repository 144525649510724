/**
 * @nurseEdu
 * 가이드에서는 ./nurseEduProjectConfig.ts에서만 URL을 정리하지만,
 * 그와 별개로 환경에 따라 필요한 URL 즉시 사용하기 위해 분리하여 선언하였습니다.
 * 
 * ./nurseEduProjectConfig.ts는 유지 보수와 통일성을 위해, linkareerProjectConfig.ts 최대한 동일한 형태로 작성하였습니다.
 */

export const NURSEEDU_API_BASE_URL =
  process.env.CBT_ENV === 'production'
    ? 'https://api.nurse-edu.co.kr'
    : 'https://apiv2-dev.nurse-edu.co.kr';

export const NURSEEDU_BASE_URL = 
  process.env.CBT_ENV === 'production'
    ? 'https://nurse-edu.co.kr'
    : 'https://dev.nurse-edu.co.kr';

export const NURSEEDU_CBT_BASE_URL = 
  process.env.CBT_ENV === 'production'
    ? 'https://cbt.nurse-edu.co.kr'
      : process.env.CBT_ENV === 'development'
        ? 'https://cbt-dev.nurse-edu.co.kr'
        : 'https://cbt-local.nurse-edu.co.kr';

export const NURSEEDU_COMMUNITY_BASE_URL = 
  process.env.CBT_ENV === 'production'
    ? 'https://nurselink.moneple.com'
    : 'https://nurselink.mnpp.cc'
