/**
 * @nurseEdu
 * 널스에듀는 개발의 효율성을 높이기 위해 외부 서비스인 shopby(샵바이)를 사용하고 있습니다.
 * 클라이언트에서 널스에듀 서버로 요청을 보낼 때도 있지만, 일부 요청은 shopby 서버로도 전달해야 하는 경우가 있습니다.
 * 이러한 경우, 클라이언트는 `/shop` 경로를 사용해 요청을 보내며, 널스에듀 서버에서 이를 shopby 서버로 프록시 처리하여 요청을 전달합니다.
 * 이 훅(hook)은 shopby 프록시 서버로 요청을 보낼 수 있는 API 클라이언트를 반환합니다.
 */

import axios from 'axios';
import { NURSEEDU_API_BASE_URL } from '../constant/nurseEduConfig';

const SHOPBY_PREPATH = '/shop' as const;

const useShopbyClient = () => {
  const shopbyClient = axios.create({
    baseURL: NURSEEDU_API_BASE_URL + SHOPBY_PREPATH,
    withCredentials: true,
    headers: {
      platform: 'PC',
    },
  });

  return { shopbyClient };
};

export default useShopbyClient;
