import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { setToastState, ToastState } from 'recoil/toast';

import useToastMessage from './hooks/useToastMessage';

import styles from './styles.module.scss';

const getToastStyles = (toastType: ToastState) => {
  switch (toastType) {
    case 'success':
      return styles.toast_type_success;
    case 'error':
      return styles.toast_type_error;
    case 'warn':
      return styles.toast_type_warn;
    default:
      return '';
  }
};

export default function Toast() {
  const toast = useRecoilValue(setToastState);
  const toastId = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [fadeOut, setFadeOut] = useState<boolean>(false);

  const { closeToastMessage } = useToastMessage();

  const removeToast = useCallback(() => {
    if (toastId.current) clearTimeout(toastId.current);
    setFadeOut(true);
    setTimeout(() => {
      closeToastMessage();
      setFadeOut(false);
    }, 350);
  }, [closeToastMessage]);

  useEffect(() => {
    if (toast.message === '') return;
    if (toastId.current) clearTimeout(toastId.current);

    toastId.current = setTimeout(() => {
      removeToast();
    }, toast.timeout);
  }, [removeToast, toast.message, toast.timeout]);

  const typeStyles = getToastStyles(toast.type || 'error');
  const commonStyles = `${styles.toast} ${fadeOut && styles.toast_fadeout}`;

  return (
    <>{toast.message && <div className={`${commonStyles} ${typeStyles}`}>{toast.message}</div>}</>
  );
}
