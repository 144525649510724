import { useMutation } from '@tanstack/react-query';
import { gqlAttachmentCompleteUpload, gqlAttachmentPrepareUpload } from '../gql/linkareer.graphql';
import { linkareerGqlRequest } from '../module/linkareerGqlRequest';
import {
  AttachmentCompleteUploadMutationResult,
  AttachmentCompleteUploadMutationVariables,
  AttachmentPrepareUploadMutationResult,
  AttachmentPrepareUploadMutationVariables,
} from '../types/gql.types';

type TAttachmentPrepareUploadInputData = {
  name: string;
  size: number;
  type: string;
  path?: string;
};

type TAttachmentComplateUploadInputData = {
  attachmentID: string;
};

export const useFileUpload = () => {
  const requestAttachmentPrepareUpload = async (inputData: TAttachmentPrepareUploadInputData) => {
    const { name, size, type, path } = inputData;

    return await linkareerGqlRequest<
      AttachmentPrepareUploadMutationResult,
      AttachmentPrepareUploadMutationVariables
    >({
      query: gqlAttachmentPrepareUpload,
      variables: {
        name,
        size,
        type,
        path,
      },
    });
  };

  const requestAttachmentComplateUpload = async (inputData: TAttachmentComplateUploadInputData) => {
    const { attachmentID } = inputData;

    return await linkareerGqlRequest<
      AttachmentCompleteUploadMutationResult,
      AttachmentCompleteUploadMutationVariables
    >({
      query: gqlAttachmentCompleteUpload,
      variables: {
        attachmentID,
      },
    });
  };

  const { mutateAsync: attachmentPrepareUpload } = useMutation<any, unknown, any, any>(
    ['linkareerAttachmentPrepareUpload'],
    async (inputData) => await requestAttachmentPrepareUpload(inputData)
  );
  const { mutateAsync: attachmentCompleteUpload } = useMutation<any, unknown, any>(
    ['linkareerAttachmentCompleteUpload'],
    async (inputData) => await requestAttachmentComplateUpload(inputData)
  );

  return {
    attachmentPrepareUpload,
    attachmentCompleteUpload,
  };
};
