import Link from 'next/link';
import { FC } from 'react';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import LinkareerLogoWithName from 'src/app.core/linkareer/svgIcon/LinkareerLogoWithName';
import styled from 'styled-components';
import HeaderCalendarIcon from './component/HeaderCalendarIcon';
import HeaderChatIconMobile from './component/HeaderChatIcon.mobile';
import HeaderNotificationIcon from './component/HeaderNotificationIcon';
import HeaderScrapIcon from './component/HeaderScrapIcon';
import SearchBarMobile from './component/SearchBar/SearchBar.mobile';
import TabBarMobile from './TabBar/TabBar.mobile';

interface IFHeaderMobileProps {
  notificationsUnreadCount?: number;
  scrapCount?: number;
  isMyCbt?: boolean;
}

const HeaderMobile: FC<IFHeaderMobileProps> = ({
  notificationsUnreadCount,
  scrapCount,
  isMyCbt,
}) => {
  return (
    <StyledWrapper>
      <div className="header-container">
        <div className="flex-box">
          <Link href={`https://${LINKAREER_DOMAIN}`} className="logo">
            <LinkareerLogoWithName height={50} width={94} />
          </Link>
        </div>
        <div className="flex-box">
          <HeaderCalendarIcon />
          <HeaderNotificationIcon notificationsUnreadCount={notificationsUnreadCount} />
          <HeaderScrapIcon scrapCount={scrapCount} />
          <HeaderChatIconMobile />
        </div>
      </div>
      {!isMyCbt && <SearchBarMobile />}
      <TabBarMobile />
    </StyledWrapper>
  );
};

export default HeaderMobile;

const StyledWrapper = styled.div`
  padding: 0 6px;
  background-color: #01a0ff;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 14px;

    .flex-box {
      height: 50px;
      display: flex;
      align-items: center;

      .logo {
        width: 94px;
        height: 54px;
        color: #fff;
        display: block;
      }
    }
  }
`;
