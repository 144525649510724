import { FC, useState } from 'react';
import { NAV_BAR_MENU_ITEMS } from 'src/app.core/linkareer/constant/navBarMenuItems';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { useCurrentUser } from 'src/app.core/linkareer/hook/useCurrentUser';
import GnbNavbarOpenIcon from 'src/app.core/linkareer/svgIcon/GnbNavbarOpenIcon';
import { UserType } from 'src/app.core/linkareer/types/gql.types';
import styled from 'styled-components';
import { UrlObject } from 'url';
import NavBarLinkWithIcon from './components/NavBarLinkWithIcon';
import NavMenuItem from './components/NavMenuItem';
import SpecManageButton from './components/SpecManageButton';
import SubNavSection from './components/SubNavSection';
import { useIsStucked } from 'src/app.core/linkareer/hook/useGnbStucked';

interface IFProps {
  userType?: UserType;
}

const NavBarDesktop: FC<IFProps> = ({ userType }) => {
  const { currentUser } = useCurrentUser();
  const [isShowNavbarDetail, setIsShowNavbarDetail] = useState<boolean>(false);
  const { isStucked, navbarRef } = useIsStucked<HTMLUListElement>();
  const isLogin = !!currentUser;

  const handleToggleNavbarDetail = () => {
    setIsShowNavbarDetail((prev) => !prev);
  };

  const careerManagementUrl: UrlObject = isLogin
    ? {
        pathname: `https://${LINKAREER_DOMAIN}/my-career/spec-management`,
        query: {
          section: 'info',
        },
      }
    : {
        pathname: `https://${LINKAREER_DOMAIN}/login`,
        query: {
          next: `https://${LINKAREER_DOMAIN}/my-career/spec-management?section=info`,
        },
      };

  return (
    <StyledWrapper>
      <div className="nav-container">
        <section className="main-nav-section">
          <nav>
            <GnbNavbarOpenIcon
              onClick={handleToggleNavbarDetail}
              className="menu-detail-icon"
              isOpen={isShowNavbarDetail}
            />
            <ul className="nav-list" ref={navbarRef}>
              {NAV_BAR_MENU_ITEMS.map((menuItem) => {
                return (
                  <NavMenuItem
                    key={`nav-menu-item-${menuItem.label}`}
                    menuInfo={menuItem}
                    activeKey={menuItem.active}
                    isShowNavbarDetail={isShowNavbarDetail}
                    isStucked={isStucked}
                  />
                );
              })}
            </ul>
          </nav>
          <div className="nav-link-icon">
            <SpecManageButton
              activityHref={careerManagementUrl}
              activityLabel="스펙 정리하기"
              userType={userType}
            />
            <NavBarLinkWithIcon
              activityHref={`https://${LINKAREER_DOMAIN}/manage/welcome`}
              activityLabel="공고등록"
            />
          </div>
        </section>
        {isShowNavbarDetail && <SubNavSection />}
      </div>
    </StyledWrapper>
  );
};

export default NavBarDesktop;

const StyledWrapper = styled.div`
  position: sticky;
  top: -1px;
  z-index: 4;
  background-color: #ffffff;
  box-shadow: 0 9px 9px -4px rgba(170, 170, 170, 0.16);
  border-top: 1px solid #eeeeee;
  margin-bottom: 96px;

  > .nav-container {
    display: flex;
    flex-direction: column;

    > .main-nav-section {
      width: 100%;
      max-width: 1100px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;

      > nav {
        display: flex;
        align-items: center;

        > .menu-detail-icon {
          width: 28px;
          height: 28px;
          margin-right: 32px;
        }

        & > .nav-list {
          display: flex;
          gap: 32px;
          flex-shrink: 0;
        }
      }
    }

    .nav-link-icon {
      display: flex;
      align-items: center;
    }
  }
`;
