import { InternalAxiosRequestConfig } from 'axios';
import { getProjectConfigByHost } from 'src/app.core/common/module/getProjectConfigByHost';
import { globalContext } from '../context';

export const addBaseUrlByHostInterceptor = (req: InternalAxiosRequestConfig) => {
  const ctx = globalContext.getCtx();
  let host = ctx?.ctx?.req?.headers?.host ?? '';
  if (typeof window !== 'undefined' && window?.location?.host) host = window?.location?.host;

  const { apiBaseProxyUrl } = getProjectConfigByHost(host);
  req.baseURL = apiBaseProxyUrl;

  return req;
};
