/**
 * @nurseEdu
 * 널스에듀에서 공통적으로 사용되는 코드입니다.
 */

import styled from 'styled-components';

type Common = 'flex-start' | 'center' | 'flex-end';

type Justify = Common | 'space-between' | 'space-around';

type Align = Common | 'stretch' | 'baseline';

interface Props {
  $justify?: Justify;
  $align?: Align;
  $vertical?: boolean;
  $wrap?: boolean;
  $gap?: number;
}

export const Flex = styled('div')<Props>`
  display: flex;
  ${({ $vertical }) => $vertical && `flex-direction: column;`}
  ${({ $justify }) => $justify && `justify-content: ${$justify};`}
  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $gap }) => $gap && `gap: ${$gap}px;`}
  ${({ $wrap }) => $wrap && `flex-wrap: wrap;`}
`;
