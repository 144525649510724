import { useQuery } from '@tanstack/react-query';
import UserApi from 'src/lib/api/users';

interface Opts {
  enabled?: boolean;
}

export const useCurrentUser = ({ enabled = true }: Opts = {}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['/users/me'],
    queryFn: () => UserApi.currentUser(),
    enabled,
    staleTime: 0,
  });

  return { user: data, isLoading };
};
