import { TExamsInfo } from 'app.type/examsInfo';
import { DefaultSeoProps, NextSeoProps } from 'next-seo';
import { BaseSeoConfig } from 'app.core/common/component/seo/BaseSeoConfig';

export class LinkareerSeoConfig extends BaseSeoConfig {
  private static createSeoTemplate({
    title,
    description,
    url,
  }: {
    title: string;
    description: string;
    url: string;
  }): NextSeoProps {
    return {
      title,
      description,
      openGraph: {
        type: 'website',
        url,
        title,
        description,
        images: [
          {
            url: '/images/linkareer/og_linkareer.png',
            width: 1200,
            height: 630,
            alt: '링커리어 CBT',
          },
        ],
      },
      additionalMetaTags: [
        { httpEquiv: 'content-type', content: 'text/html' },
        { property: 'article:publisher', content: 'https://linkareer.com/' },
        { property: 'fb:app_id', content: '265463423907972' },
        { property: 'fb:pages', content: '1715100552083537' },
        { name: 'application-name', content: '링커리어' },
        { name: 'apple-mobile-web-app-title', content: '링커리어' },
        { name: 'theme-color', content: '#01a0ff' },
        { name: 'naver-site-verification', content: '15499fdace3c056368e1c4e66ba226b02d36db33' },
        { name: 'google-site-verification', content: 'WkmCDF4rKmWTqtuvU8JEh6q17ko8y83lomF-UzftiM' },

        { name: 'twitter:image', content: '/images/linkareer/og_linkareer.png' },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ],
    };
  }

  getDefaultSeoMessage(data?: unknown): DefaultSeoProps {
    return {
      title: '링커리어 CBT | 공기업, 대기업, PSAT, 한국사 시험 준비',
      description:
        '최신 기출을 반영해 복원한 문제로 공기업, 대기업, PSAT, 한국사 시험을 대비하세요. 실제 취업 합격자를 다수 배출한 위포트에서 제공하는 검증된 문제로 효과적인 학습을 경험하세요!',
      canonical: 'https://cbt.linkareer.com/',
      openGraph: {
        type: 'website',
        url: 'https://linkareer.com/',
        title: '링커리어 CBT | 공기업, 대기업, PSAT, 한국사 시험 준비',
        description:
          '최신 기출을 반영해 복원한 문제로 공기업, 대기업, PSAT, 한국사 시험을 대비하세요. 실제 취업 합격자를 다수 배출한 위포트에서 제공하는 검증된 문제로 효과적인 학습을 경험하세요!',
        images: [
          {
            url: '/images/linkareer/og_linkareer.png',
            width: 1200,
            height: 630,
            alt: '링커리어 CBT',
          },
        ],
      },
      additionalMetaTags: [
        { httpEquiv: 'content-type', content: 'text/html' },
        { property: 'article:publisher', content: 'https://linkareer.com/' },
        { property: 'fb:app_id', content: '265463423907972' },
        { property: 'fb:pages', content: '1715100552083537' },
        { name: 'application-name', content: '링커리어' },
        { name: 'apple-mobile-web-app-title', content: '링커리어' },
        { name: 'theme-color', content: '#01a0ff' },
        { name: 'naver-site-verification', content: '15499fdace3c056368e1c4e66ba226b02d36db33' },
        { name: 'google-site-verification', content: 'WkmCDF4rKmWTqtuvU8JEh6q17ko8y83lomF-UzftiM' },

        { name: 'twitter:image', content: '/images/linkareer/og_linkareer.png' },
        { name: 'twitter:title', content: '링커리어 CBT | 공기업, 대기업, PSAT, 한국사 시험 준비' },
        {
          name: 'twitter:description',
          content:
            '최신 기출을 반영해 복원한 문제로 공기업, 대기업, PSAT, 한국사 시험을 대비하세요. 실제 취업 합격자를 다수 배출한 위포트에서 제공하는 검증된 문제로 효과적인 학습을 경험하세요!',
        },
      ],
      additionalLinkTags: [
        {
          rel: 'apple-touch-icon',
          href: '/images/linkareer/ic-launcher-192.png',
        },
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: '/images/linkareer/favicon.ico',
        },
      ],
    };
  }

  getExamListSeoMessage(type?: string): NextSeoProps {
    const seoMapping: Record<string, { title: string; description: string }> = {
      'public-company': {
        title: '공기업 필기시험 준비 | 공기업 최신 기출 문제 풀이 - 링커리어 CBT',
        description:
          '링커리어 CBT에서 최신 공기업 기출 문제를 실전처럼 풀어보세요. 위포트 제공, 실전 대비 문제로 한국철도공사, 한국전력공사, NCS 시험을 완벽하게 준비하세요.',
      },
      'major-company': {
        title: '대기업 인적성 준비 | 최신 수리·언어·도형 추리 문제 풀이 - 링커리어 CBT',
        description:
          '링커리어 CBT에서 최신 대기업 기출 문제를 실전처럼 풀어보세요. 위포트 제공 문제로 GSAT, SKCT, LG Way Fit 시험을 체계적으로 준비하세요.',
      },
      'psat': {
        title: 'PSAT 시험 준비 | 최신 자료해석·언어논리·상황판단 문제 풀이 - 링커리어 CBT',
        description:
          '링커리어 CBT에서 최신 PSAT 기출 문제로 자료해석, 언어논리, 상황판단 영역을 완벽히 대비하세요. 최다 합격자 배출 위포트 제공 문제로 실전 감각을 기르세요.',
      },
      'korean-history': {
        title: '한국사 능력 검정 시험 준비 | 최신 기출 문제 풀이로 완벽 대비 - 링커리어 CBT',
        description:
          '링커리어 CBT에서 최신 한국사 기출 문제를 풀고 실전 대비하세요. 위포트에서 직접 제공한 문제로 한능검 1급 시험 완벽 대비!',
      },
    };

    const seoData = seoMapping[type as string] || {
      title: '링커리어 CBT | 공기업, 대기업, PSAT, 한국사 시험 준비',
      description:
        '최신 기출을 반영해 복원한 문제로 공기업, 대기업, PSAT, 한국사 시험을 대비하세요. 실제 취업 합격자를 다수 배출한 위포트에서 제공하는 검증된 문제로 효과적인 학습을 경험하세요!',
    };

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title: seoData.title,
        description: seoData.description,
        url: `https://cbt.linkareer.com/exam/${type}`,
      }),
    };
  }

  getExamDetailSeoMessage(data: TExamsInfo): NextSeoProps {
    const examType = (data?.examTypes || []).filter(
      (type: { parentId: number | null; name: string }) => !type?.parentId
    );
    const examTypeName = String(examType[0]?.name || '');

    const seoMapping: Record<string, { title: string; description: string }> = {
      공기업: {
        title: `공기업 필기시험 준비 | 한국철도공사·건보공단·한전·서울교통공사·NCS 통합모의고사 - 링커리어 CBT`,
        description:
          '링커리어 CBT에서 한국철도공사, 국민건강보험공단, 한국전력공사, 서울교통공사 필기시험 및 NCS 통합모의고사를 준비하세요. 최신 기출 문제와 출제 경향을 반영한 맞춤형 학습으로 공기업 취업 합격을 목표로 실력을 키우세요.',
      },
      대기업: {
        title: `대기업 인적성·필기시험 대비 | 삼성 GSAT, SKCT, LG Way FIt, SSAFY - 링커리어 CBT`,
        description:
          '링커리어 CBT에서 삼성, SK하이닉스, LG, SSAFY 인적성 및 필기시험을 철저히 대비하세요. 실전과 유사한 환경에서 맞춤형 문제 풀이, 상세한 해설과 오답 분석을 통해 독학으로도 문제 해결 능력을 높이고 취업 성공 가능성을 강화하세요.',
      },
      PSAT: {
        title: 'PSAT·공무원 시험 준비 | 5급·7급·9급 공직 적격성 대비 - 링커리어 CBT',
        description:
          '링커리어 CBT에서 5급, 7급, 9급 PSAT 및 공무원 국어 시험을 준비하세요. 자료해석, 언어논리, 상황판단 등 최신 기출 문제와 예시 문제로 실전 감각을 키우고, 공직 채용 합격을 목표로 철저히 대비하세요.',
      },
      한국사: {
        title: `한국능력검정(심화) 대비 | 고난도 문제 및 해설 - 링커리어 CBT`,
        description: `링커리어 CBT에서 한국능력검정(심화) 시험 대비를 위한 고난도 문제 풀이와 상세한 해설, 오답 분석을 제공합니다. 독학으로도 문제 해결 능력을 높일 수 있는 심화 학습 자료로 완벽히 준비하세요.`,
      },
    };

    const seoData = seoMapping[examTypeName as string] || {
      title: '링커리어 CBT | 공기업, 대기업, PSAT, 한국사 시험 준비',
      description:
        '최신 기출을 반영해 복원한 문제로 공기업, 대기업, PSAT, 한국사 시험을 대비하세요. 실제 취업 합격자를 다수 배출한 위포트에서 제공하는 검증된 문제로 효과적인 학습을 경험하세요!',
    };

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title: seoData.title,
        description: seoData.description,
        url: `https://cbt.linkareer.com/exam/${data.examIdx}`,
      }),
    };
  }

  getExamTypeSelectionSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = '한문제씩 풀기 또는 CBT 모의고사 선택 | 링커리어 CBT 시험 풀이 방식 선택';
    const description =
      '링커리어 CBT에서 한문제씩 풀거나 실전처럼 모의고사 모드로 연습하세요. 공기업, 대기업 인적성 시험 준비를 위한 최적의 방식으로 실력을 키우세요.';
    const url = `https://cbt.linkareer.com/cbt/select/${examDetailIdx}`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getCbtOneQuestionTypeSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = '한문제씩 풀기 모드로 집중 학습 | 링커리어 CBT로 시험 대비';
    const description =
      '링커리어 CBT의 한문제씩 풀기 모드로 공기업, 대기업 인적성 시험 대비 문제를 단계별로 풀어보세요. 효율적인 학습을 통해 실력을 차근차근 향상시키세요.';
    const url = `https://cbt.linkareer.com/cbt/onequestions/${examDetailIdx}`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getCbtPracticeTypeSeoMessage(examDetailIdx: string): NextSeoProps {
    const title = 'CBT 모의고사로 실전처럼 연습 | 링커리어 시험 대비';
    const description =
      '링커리어 CBT 모의고사 모드에서 실제 시험 화면과 동일한 환경으로 공기업, 대기업 인적성 시험을 준비하세요. 실전 감각을 기르고 자신감을 높이세요';
    const url = `https://cbt.linkareer.com/cbt/practice/${examDetailIdx}`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getExamResultSeoMessage(recordIdx: string): NextSeoProps {
    const title = 'CBT 시험 결과 및 분석 | 링커리어 성적 피드백 제공';
    const description =
      '링커리어 CBT에서 시험 결과를 확인하고, 세부 성적 분석으로 실력을 진단하세요. 공기업·대기업 인적성 시험 준비를 위한 피드백으로 더욱 효율적인 학습을 도와드립니다.';
    const url = `https://cbt.linkareer.com/cbt/practice/score/${recordIdx}`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getExamSolutionSeoMessage(recordIdx: string): NextSeoProps {
    const title = 'CBT 시험 해설 및 정답 확인 | 링커리어 문제 분석 제공';
    const description =
      '링커리어 CBT에서 시험 문제의 정답과 해설을 확인하세요. 상세한 문제 분석과 설명을 통해 공기업·대기업 인적성 시험 대비 실력을 향상시킬 수 있습니다.';
    const url = `https://cbt.linkareer.com/cbt/practice/score/${recordIdx}`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTArchiveSeoMessage(): NextSeoProps {
    const title = '나의 관심 시험 | 링커리어 CBT 맞춤형 학습 기록';
    const description =
      "링커리어 '나의 관심 시험' 페이지에서 관심 있는 공기업·대기업 인적성 시험을 관리하고, 맞춤형 학습 기록을 확인하세요. 효과적인 학습 계획으로 시험 준비를 완벽하게 대비하세요.";
    const url = `https://cbt.linkareer.com/mycbt/archive`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTResultSeoMessage(): NextSeoProps {
    const title = 'CBT 결과보기 | 링커리어 시험 성적 분석 및 피드백';
    const description =
      "링커리어 'CBT 결과보기'에서 모든 시험의 성적을 확인하고, 상세한 분석과 피드백으로 실력을 향상하세요. 공기업·대기업 인적성 시험 대비에 효과적인 맞춤형 학습을 지원합니다";
    const url = `https://cbt.linkareer.com/mycbt/review`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }

  getMyCBTNoteSeoMessage(): NextSeoProps {
    const title = '오답노트 | 링커리어 CBT 틀린 문제 복습 및 재학습';
    const description =
      "링커리어 '오답노트'에서 틀린 문제를 다시 풀어보고 복습하세요. 공기업·대기업 인적성 시험 대비를 위한 재학습으로 취약점을 보완하고 실력을 강화하세요.";
    const url = `https://cbt.linkareer.com/mycbt/note`;

    return {
      ...LinkareerSeoConfig.createSeoTemplate({
        title,
        description,
        url,
      }),
    };
  }
}
