import { selector } from 'recoil';
import { ProjectConfig } from 'src/app.core/common/module/projectConfigs';
import { hostState } from './hostState';
import { getProjectConfigByHost } from 'src/app.core/common/module/getProjectConfigByHost';

export const projectConfigState = selector<ProjectConfig>({
  key: 'projectConfigState',
  get: ({ get }) => {
    const host = get(hostState);
    return getProjectConfigByHost(host);
  },
});
