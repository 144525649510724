import axios from "axios";
import { NURSEEDU_API_BASE_URL } from "../constant/nurseEduConfig";

const axiosDefaultOptions = {
  baseURL: NURSEEDU_API_BASE_URL,
  withCredentials: true,
  timeout: 1000 * 10,
};

const axiosClient = axios.create(axiosDefaultOptions);

export default axiosClient;
