// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
// https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
import mime from 'mime-types';

export enum DOCUMENT_TYPE {
  PLAIN_TEXT = 'PLAIN_TEXT',
  WORD_DOCUMENT = 'WORD_DOCUMENT',
  PRESENTATION = 'PRESENTATION',
  SPREADSHEET = 'SPREADSHEET',
  PDF = 'PDF',
  IMAGE = 'IMAGE',
}

export enum MIME_TYPE {
  // PLAIN_TEXT
  TXT = 'text/plain',

  // WORD_DOCUMENT
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOTX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  DOCM = 'application/vnd.ms-word.document.macroEnabled.12',
  DOTM = 'application/vnd.ms-word.template.macroEnabled.12',
  HAN_DOCX = 'application/haansoftdocx',
  HAN_DOC = 'application/haansoftdoc',

  // PRESENTATION
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  POTX = 'application/vnd.openxmlformats-officedocument.presentationml.template',
  PPSX = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PPAM = 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  PPTM = 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  PPSM = 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',

  // SPREADSHEET
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLTX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  XLSM = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  XLTM = 'application/vnd.ms-excel.template.macroEnabled.12',
  XLAM = 'application/vnd.ms-excel.addin.macroEnabled.12',

  // PDF
  PDF = 'application/pdf',

  // IMAGE
  BMP = 'image/bmp',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  WEBP = 'image/webp',
}

export const DOCUMENT_TO_MIME_TYPES = {
  [DOCUMENT_TYPE.PLAIN_TEXT]: [MIME_TYPE.TXT],
  [DOCUMENT_TYPE.WORD_DOCUMENT]: [
    MIME_TYPE.DOC,
    MIME_TYPE.DOCX,
    MIME_TYPE.DOTX,
    MIME_TYPE.DOCM,
    MIME_TYPE.DOTM,
    MIME_TYPE.HAN_DOCX,
    MIME_TYPE.HAN_DOC,
  ],
  [DOCUMENT_TYPE.PRESENTATION]: [
    MIME_TYPE.PPT,
    MIME_TYPE.PPTX,
    MIME_TYPE.POTX,
    MIME_TYPE.PPSX,
    MIME_TYPE.PPAM,
    MIME_TYPE.PPTM,
    MIME_TYPE.PPSM,
  ],
  [DOCUMENT_TYPE.SPREADSHEET]: [
    MIME_TYPE.CSV,
    MIME_TYPE.XLS,
    MIME_TYPE.XLSX,
    MIME_TYPE.XLTX,
    MIME_TYPE.XLSM,
    MIME_TYPE.XLTM,
    MIME_TYPE.XLAM,
  ],
  [DOCUMENT_TYPE.PDF]: [MIME_TYPE.PDF],
  [DOCUMENT_TYPE.IMAGE]: [
    MIME_TYPE.BMP,
    MIME_TYPE.JPG,
    MIME_TYPE.PNG,
    MIME_TYPE.SVG,
    MIME_TYPE.WEBP,
  ],
};

export type EDocumentToMimeTypes = {
  [x in DOCUMENT_TYPE]: Array<MIME_TYPE>;
};

export const MIME_TO_EXTENSION = (mimeTypes: string[]): string[] => {
  return mimeTypes
    .map((mimeType) => mime.extension(mimeType))
    .filter((ext) => typeof ext === 'string') as string[];
};
