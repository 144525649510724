import Link from 'next/link';
import { FC } from 'react';
import AlarmBorderIcon from 'src/app.core/linkareer/svgIcon/AlarmBorderIcon';
import styled from 'styled-components';
import NotificationBadge from './NotificationBadge';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';

interface IFHeaderNotificationIconProps {
  notificationsUnreadCount?: number;
}

const HeaderNotificationIcon: FC<IFHeaderNotificationIconProps> = ({
  notificationsUnreadCount,
}) => {
  return (
    <StyledWrapper>
      <Link href={`https://${LINKAREER_DOMAIN}/notifications`} className="icon-button">
        <AlarmBorderIcon className="alarm-icon" width={20} height={20} />
        {Number(notificationsUnreadCount) > 0 && (
          <NotificationBadge count={notificationsUnreadCount} />
        )}
      </Link>
    </StyledWrapper>
  );
};

export default HeaderNotificationIcon;

const StyledWrapper = styled.div`
  width: 36px;

  .icon-button {
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    align-items: center;
    position: relative;

    .alarm-icon {
      & g {
        stroke: #fff;
      }
    }
  }
`;
