import axios from 'axios';
import { LINKAREER_API_BASE_URL } from '../constant/urls';

type RequestParams<T> = {
  query: string;
  variables?: T;
};

type AxiosRequestResponse<T> = {
  data: T;
};

/**
 * @description 링커리어 API에 GraphQL 쿼리와 변수를 사용하는 함수
 * @template T - API 요청의 응답 데이터 타입
 * @template K - GraphQL 쿼리에 전달되는 변수들의 타입
 * @example
 * ```typescript
 * const query = `
 *   query CurrentUser($notificationType: NotificationScope) {
 *     currentUser {
 *       id
 *       username
 *       notificationUnreadCount(notificationType: $notificationType)
 *     }
 *   }
 * `;
 *
 * const variables = { notificationType: 'NOTIFICATION' };
 * const userData = await linkareerGqlRequest<T, K>({
 *   query,
 *   variables,
 * });
 * ```
 */
export const linkareerGqlRequest = async <T, K = undefined>({
  query,
  variables,
}: RequestParams<K>): Promise<T> => {
  const { data } = await axios.request<AxiosRequestResponse<T>>({
    method: 'POST',
    url: `${LINKAREER_API_BASE_URL}/graphql`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      query,
      variables: {
        ...variables,
      },
    },
    withCredentials: true,
  });

  return data.data;
};
