const objectToGetParams = (object: {
  [key: string]: string | number | undefined | null
}) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )

  return params.length > 0 ? `?${params.join('&')}` : ''
}

const facebookLink = (
  url: string,
  { quote, hashtag }: { quote?: string; hashtag?: string },
) => {
  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      quote,
      hashtag,
    })
  )
}

export default facebookLink
