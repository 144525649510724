import { FC } from 'react';
import Portal from 'src/app.core/linkareer/component/portal/Portal';
import CloseIcon from 'src/app.core/linkareer/svgIcon/CloseIcon';
import styled from 'styled-components';

interface IFNotManagerModalProps {
  onClose: () => void;
}

const NotManagerModal: FC<IFNotManagerModalProps> = ({ onClose }) => {
  const handleClickCloseModal = () => {
    onClose();
  };

  return (
    <Portal>
      <StyledWrapper>
        <article>
          <header>
            <CloseIcon onClick={handleClickCloseModal} />
          </header>
          <section>
            <p>개인회원만 이용할 수 있는 서비스입니다.</p>
          </section>
          <footer>
            <button onClick={handleClickCloseModal}>확인</button>
          </footer>
        </article>
      </StyledWrapper>
    </Portal>
  );
};

export default NotManagerModal;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 10000;
  overflow-y: auto;

  article {
    max-width: 600px;
    display: flex;
    max-height: calc(100% - 10px);
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;

    > header {
      position: absolute;
      top: 20px;
      right: 20px;
      > svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    > section {
      padding: 50px 50px 0;

      > p {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin: 0;
      }
    }

    > footer {
      padding: 30px;

      > button {
        width: 100%;
        height: 50px;
        background-color: #01a0ff;
        color: #ffffff;
        font-family: 'Noto Sans KR';
        padding: 13px 15px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 8px;
        letter-spacing: -0.6px;
      }
    }
  }
`;
