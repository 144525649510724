import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const AlarmBorderIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="알림"
      {...props}
    >
      <desc>알림</desc>
      <defs>
        <clipPath id="727p20k0da">
          <path fill="none" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
      <g fill="none" stroke="#666" clipPath="url(#727p20k0da)">
        <path d="M12.932 11.957H2.99a.335.335 0 010-.669h.5V6.572a4.471 4.471 0 118.942 0v4.716h.5a.335.335 0 010 .669z"></path>
        <path d="M9.752 11.822a1.9 1.9 0 11-3.572-.029"></path>
      </g>
    </svg>
  );
};

export default AlarmBorderIcon;
