import Image from 'next/image';
import { FC } from 'react';
import { APP_STORE_URL, PLAY_STORE_URL } from 'src/app.core/linkareer/constant/urls';
import styled from 'styled-components';

const FooterAppInstall: FC = () => {
  return (
    <StyledWrapper>
      <Image
        width={144}
        height={34}
        className="logo"
        src="/images/linkareer/gray-logo.png"
        alt="푸터-로고"
        quality={90}
      />
      <div className="store-badge-list">
        <a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
          <Image
            src="/images/linkareer/google-play-badge.png"
            alt="플레이스토어"
            width={144}
            height={44}
          />
        </a>
        <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
          <Image
            src="/images/linkareer/app-store-badge.png"
            alt="앱스토어"
            width={144}
            height={44}
          />
        </a>
      </div>
    </StyledWrapper>
  );
};

export default FooterAppInstall;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .footer-logo {
    width: 128px;
    height: 50px;
    color: #ccc;

    img {
      width: 100%;
    }
  }
  .store-badge-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
  }
`;
