import { FC } from 'react'
import styled from 'styled-components'

interface IFChattingIframeSkeletonProps {
  className?: string
}

const ChattingIframeSkeleton: FC<IFChattingIframeSkeletonProps> = ({
  className,
}) => {
  return <StyledWrapper className={className} />
}

export default ChattingIframeSkeleton

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out 0.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`
