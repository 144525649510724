import { MutableRefObject, useEffect } from 'react';
import {
  HeaderNavItemType,
  NavBarActiveKey,
} from 'src/app.core/linkareer/constant/navBarMenuItems';

/**
 * @description 모바일 nav 리스트의 뒤쪽 탭이 활성화 될 경우 활성화 된 탭을 화면에 표시합니다
 * @param parentRef - 스크롤 효과를 적용할 부모 요소의 ref
 * @param navItems - 스크롤 효과를 적용할 요소들의 목록
 * @param targetTab - 현재 활성화된 탭
 *  */
interface UseNavTabScrollIntoViewProps {
  parentRef: MutableRefObject<HTMLAnchorElement[] | null[]>;
  navItems: HeaderNavItemType[];
  targetTab: NavBarActiveKey;
}

export const useNavTabScrollIntoView = ({
  parentRef,
  navItems,
  targetTab,
}: UseNavTabScrollIntoViewProps) => {
  useEffect(() => {
    navItems.forEach((menuItem, index) => {
      const isActiveTab = targetTab === menuItem.active;

      if (isActiveTab && parentRef.current[index]) {
        const parentElement = parentRef.current[index]?.parentElement;
        const childElement = parentRef.current[index];

        if (parentElement && childElement) {
          const childRect = childElement.getBoundingClientRect();
          const parentRect = parentElement.getBoundingClientRect();

          const offset = 20;
          const scrollPosition =
            parentElement.scrollLeft + (childRect.left - parentRect.left) - offset;

          parentElement.scrollTo({
            left: scrollPosition,
          });
        }
      }
    });
  }, [targetTab]);
};
