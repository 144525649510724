import React from 'react';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';

const Footer = () => {
  const { footer: Footer } = useRecoilValue(projectConfigState);

  return <Footer />;
};

export default Footer;
