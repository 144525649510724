export type TChattingQueryParamKeys =
  | 'accessToken'
  | 'chatRoomId'
  | 'chatMessageId'

export const CHAT_OPEN_TYPE_QUERY_KEY = 'chatOpenType' as const

export enum CHAT_OPEN_TYPE {
  DEFAULT = 'default',
  ONLY_ROOM = 'only-room',
}
