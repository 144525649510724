import { useMutation } from '@tanstack/react-query';
import { gqlCreateSuggestionMutation } from '../gql/linkareer.graphql';
import { linkareerGqlRequest } from '../module/linkareerGqlRequest';
import {
  CreateSuggestionMutationResult,
  CreateSuggestionMutationVariables,
} from '../types/gql.types';

type TCreateSuggestionInput = {
  emailInput: string;
  contentText: string;
  attachmentIds: string[];
};

export const useCreateSuggestion = () => {
  const filter = 'win16|win32|win64|mac|macintel';

  const requestSuggestion = async (inputData: TCreateSuggestionInput) => {
    const { emailInput, contentText, attachmentIds } = inputData;

    const variables: CreateSuggestionMutationVariables = {
      input: {
        email: emailInput,
        content: contentText,
        attachmentIds: attachmentIds,
        deviceInfo: filter.indexOf(navigator.platform.toLowerCase()) < 0 ? 'MOBILE' : 'PC',
      },
    };

    return await linkareerGqlRequest<
      CreateSuggestionMutationResult,
      CreateSuggestionMutationVariables
    >({
      query: gqlCreateSuggestionMutation,
      variables,
    });
  };

  const { mutate: createSuggestion } = useMutation<any, unknown, TCreateSuggestionInput>(
    ['linkareerCreateSuggesion'],
    async (inputData) => requestSuggestion(inputData)
  );

  return {
    createSuggestion,
  };
};
