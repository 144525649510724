import { axiosClient } from './axios';
import qs from 'qs';

const UserApi = {
  currentUser: async () => {
    try {
      const response = await axiosClient.get('/apiV2/auth/profile');
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      //console.log(error);
      return null;
    }
    return '';
  },
  getUserLikes: async () => {
    try {
      const response = await axiosClient.get('/apiV2/user/me/likes/exams');
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
    return '';
  },
  getUserReviewList: async (examTypeIds: string | null, page = 1, pageSize: number = 1000) => {
    try {
      const queryString = qs.stringify(
        { ...(examTypeIds ? { examTypeIds } : {}), page, pageSize },
        { addQueryPrefix: true, indices: false }
      );
      const response = await axiosClient.get(`/apiV2/user-exam-records${queryString}`);
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUserReviewNote: async (
    query: {
      problemType?: string;
      page?: number;
      pageSize?: number;
      sort?: string[];
    } = {}
  ) => {
    try {
      const response = await axiosClient({
        method: 'GET',
        url:
          '/apiV2/user/me/review-notes' +
          qs.stringify(query, { addQueryPrefix: true, skipNulls: true }),
      });
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUserReviewNoteAggregate: async (
    query: {
      problemType?: string;
      page?: number;
      pageSize?: number;
      sort?: string[];
    } = {}
  ) => {
    try {
      const response = await axiosClient({
        method: 'GET',
        url:
          '/apiV2/user/me/review-notes/aggregate' +
          qs.stringify(query, { addQueryPrefix: true, skipNulls: true }),
      });
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default UserApi;
