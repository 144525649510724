import Image from 'next/image';
import LinkareerCheckIcon from 'src/app.core/linkareer/svgIcon/LinkareerCheckIcon';
import styled from 'styled-components';
import Portal from '../../portal/Portal';

interface PromotionPopupProps {
  id: string;
  onClickBanner: () => void;
  onClickClose: () => void;
  onClickTodayClose: () => void;
}

const PromotionPopup = ({
  id,
  onClickBanner,
  onClickClose,
  onClickTodayClose,
}: PromotionPopupProps) => {
  const handleClickClose = () => {
    onClickClose();
  };

  const handleClickTodayClose = () => {
    onClickTodayClose();
  };

  const handleClickBanner = () => {
    onClickBanner();
  };

  return (
    <Portal id={id}>
      <StyledWrapper>
        <aside role="dialog">
          <div className="banner-image" role="banner" onClick={handleClickBanner}>
            <figure>
              <figcaption>삼성 GSAT 풀서비스</figcaption>
              <Image
                src="/images/linkareer/gsat-service-banner.png"
                alt="삼성 GSAT 풀서비스 사전예약 바로가기"
                fill
              />
            </figure>
          </div>
          <div className="banner-button-wrapper">
            <button type="button" onClick={handleClickTodayClose}>
              <i className="icon-checkbox-button">
                <LinkareerCheckIcon />
              </i>
              <p>오늘 하루 보지 않기</p>
            </button>
            <button type="button" onClick={handleClickClose}>
              <p>닫기</p>
            </button>
          </div>
        </aside>
      </StyledWrapper>
    </Portal>
  );
};

export default PromotionPopup;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 10000;
  overflow-y: auto;

  & > aside {
    width: 500px;
    height: 450px;
    border-radius: 15px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > .banner-image {
      width: 100%;
      height: 390px;
      position: relative;
      cursor: pointer;

      & > figure {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        & > figcaption {
          width: 0;
          height: 0;
          font-size: 0;
        }
      }
    }

    & > .banner-button-wrapper {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;

      & > button {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #dddddd;
        }

        & > .icon-checkbox-button {
          width: 28px;
          height: 28px;
        }

        & > p {
          height: 16px;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.64px;
          color: #333333;
          padding: 0;
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      width: 340px;
      height: 315px;

      & > .banner-image {
        width: 340px;
        height: 265px;
      }

      & > .banner-button-wrapper {
        height: 50px;

        & > button {
          & > .icon-checkbox-button {
            width: 24px;
            height: 24px;
          }

          & > p {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.56px;
          }
        }
      }
    }
  }
`;
