// https://github.com/cnblogs/vscode-cnb/blob/f01fa50a1db69a5835367d7c4983f39fd0b0a72c/src/ctx/global-ctx.ts#
import { AppContext } from 'next/app';

// let globalContext: AppContext | undefined;

class GlobalContext {
  private ctx: AppContext | undefined;

  setCtx(_ctx: AppContext) {
    this.ctx = _ctx;
  }

  getCtx() {
    return this.ctx;
  }
}

export const globalContext = new GlobalContext();
