import { FC } from 'react';
import TSvgProps from 'src/app.core/linkareer/types/svgPropsType';

const ExpandMoreBoldIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6.364"
      viewBox="0 0 10 6.364"
      aria-label="확장"
      {...props}
    >
      <desc>확장</desc>
      <path
        fill="currentColor"
        d="M0 1.022a1.01 1.01 0 01.266-.69.865.865 0 011.285 0l3.427 3.677L8.448.286a.865.865 0 011.285 0 1.025 1.025 0 010 1.379L5.621 6.078a.866.866 0 01-1.285 0L.266 1.712A1.014 1.014 0 010 1.022z"
      />
    </svg>
  );
};

export default ExpandMoreBoldIcon;
