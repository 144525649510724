/**
 * @nurseEdu
 * 널스에듀는 카테고리 gnb는 서버에서 값을 내려줍니다.
 * 해당 코드는 카테고리 gnb를 가져와 Header에 표시하기 위해 사용됩니다.
 */

import { useEffect, useState } from 'react';
import axiosClient from '../lib/api';
import { NURSEEDU_API_BASE_URL } from '../constant/nurseEduConfig';

interface CategoryDto {
  categoryId: number;
  parentId: number;
  displayOrder: number;
  count: number;
  label: string;
  children?: CategoryDto[];
}

export const useHeaderCategories = () => {
  const [categories, setCategories] = useState<CategoryDto[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const url = `${NURSEEDU_API_BASE_URL}/v2/categories/gnb`;
      const { data } = await axiosClient.get<CategoryDto[]>(url);
      setCategories(data);
    } catch (error) {
      console.error(error);
      setError('API 에러');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return {
    categories,
    isLoading,
    error,
  };
};
