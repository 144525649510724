import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const useChattingLazyLoad = (cb: Function) => {
  const isCalled = useRef(false)

  const doCallbackOnce = () => {
    if (isCalled.current === true) return
    isCalled.current = true
    cb()
  }

  useEffect(() => {
    const timeoutCallback = setTimeout(() => {
      doCallbackOnce()
    }, 5000)

    window.addEventListener('load', doCallbackOnce)

    return () => {
      window.removeEventListener('load', doCallbackOnce)
      clearTimeout(timeoutCallback)
    }
  }, [cb])
}

export default useChattingLazyLoad
