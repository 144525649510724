import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const MagnifyingGlassIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="MagnifyingGlass"
      width="16"
      height="16"
      data-name="MagnifyingGlass"
      viewBox="0 0 16 16"
      aria-label="돋보기"
      {...props}
    >
      <desc>돋보기</desc>
      <path
        id="MagnifyingGlass"
        d="M663.427 114.358l-3.4-3.4a6.853 6.853 0 1 0-1.414 1.414l3.4 3.4a1 1 0 1 0 1.414-1.414zm-13.707-7.465a4.828 4.828 0 1 1 4.828 4.828 4.834 4.834 0 0 1-4.828-4.828z"
        fill="currentColor"
        data-name="MagnifyingGlass"
        transform="translate(-647.72 -100.065)"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
