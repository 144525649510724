import React from 'react';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';

const Header: React.FC = () => {
  const { header } = useRecoilValue(projectConfigState);
  const HeaderComponent = header;

  return HeaderComponent ? <HeaderComponent /> : null;
};

export default Header;
