import { atom, selector } from 'recoil';

export type ToastState = 'error' | 'success' | 'warn';
export interface IToast {
  message: string;
  timeout?: number;
  type?: ToastState;
}

const ToastState = atom<IToast>({
  key: 'toastState',
  default: {
    message: '',
    timeout: 1500,
    type: 'error',
  },
});

export const setToastState = selector({
  key: 'setToastState',
  get: ({ get }) => get(ToastState),
  set: ({ set }, newValue) => {
    set(ToastState, newValue as IToast);
  },
});
