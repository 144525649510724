import { FC } from 'react';
import styled from 'styled-components';

const NavBarTooltip: FC = () => {
  return (
    <StyledWrapper role="tooltip">
      <p className="tooltip-text">100% 무료</p>
    </StyledWrapper>
  );
};

export default NavBarTooltip;

const StyledWrapper = styled.div`
  width: 73px;
  height: 22px;
  background-color: #ffffff;
  border: 1px solid #ef2929;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ef2929;
  padding: 0 9px;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border: 1px solid;
    border-color: transparent #ef2929 #ef2929 transparent;
    border-bottom-right-radius: 2px;
  }

  .tooltip-text {
    width: 73px;
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: -0.44px;
    text-align: center;
    color: #ef2929;
    position: absolute;
  }
`;
