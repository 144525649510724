import { useRouter } from 'next/router';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import MagnifyingGlassIcon from 'src/app.core/linkareer/svgIcon/MagnifyingGlassIcon';
import styled from 'styled-components';

interface IFProps {}

type TForm = {
  search: string;
};

const SearchBarDesktop: FC<IFProps> = () => {
  const router = useRouter();
  const { register, handleSubmit } = useForm<TForm>();

  const onSubmit = (data: TForm) => {
    if (!data.search) return;

    router.push({
      pathname: `https://${LINKAREER_DOMAIN}/search`,
      query: {
        ...router.query,
        q: data.search,
        p: 1,
      },
    });
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <input
          id="header-search"
          type="search"
          placeholder="대외활동, 인턴, 채용 정보를 검색해보세요"
          defaultValue={router.query.q || ''}
          {...register('search')}
        />
        <button className="search-icon-btn">
          <MagnifyingGlassIcon width={22} height={22} />
        </button>
      </form>
    </StyledWrapper>
  );
};

export default SearchBarDesktop;

const StyledWrapper = styled.div`
  .form {
    position: relative;
    display: flex;

    input {
      width: 370px;
      height: 54px;
      border: 2px solid #01a0ff;
      font-size: 16px;
      font-weight: 500;
      padding: 0 45px 0 15px;
      outline: none;
      border-radius: 10px;
      -webkit-appearance: textfield; // ios 에서 input type search일 경우 나타나는 돋보기 스타일 제거
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    .search-icon-btn {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;
