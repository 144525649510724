import * as React from 'react';
import styled from 'styled-components';
import { LINKAREER_BIZ_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { useIsUaMobile } from 'src/app.core/linkareer/hook/useIsUaMobile';
import CloseIcon from 'src/app.core/linkareer/svgIcon/CloseIcon';
import Portal from 'src/app.core/linkareer/component/portal/Portal';

interface IFProps {
  onClickCloseModal: () => void;
}

const ChannelUnavailableModal: React.FC<IFProps> = ({ onClickCloseModal }) => {
  const { isMobile } = useIsUaMobile();

  const handleClickConfirm = () => {
    window.open(`${LINKAREER_BIZ_DOMAIN}/profile`);
    if (onClickCloseModal) {
      onClickCloseModal();
    }
  };

  return (
    <Portal>
      <StyledWrapper className="common-modal" data-is-mobile={isMobile ? 'mobile' : 'desktop'}>
        <article>
          <div className="close-btn" onClick={onClickCloseModal}>
            <CloseIcon />
          </div>
          <div className="modal-title">채널 서비스 이용 불가</div>
          <div className="modal-description">
            기업 회원 가입이 승인되지 않아 채널 서비스 이용이 어렵습니다.
            <br />
            사업자 등록번호를 올바르게 기입해 기업회원 승인 후 이용해주세요.
          </div>
          <div className="btn-list">
            <button className="btn" onClick={handleClickConfirm}>
              사업자 등록번호 확인하기
            </button>
          </div>
        </article>
      </StyledWrapper>
    </Portal>
  );
};

export default ChannelUnavailableModal;

const StyledWrapper = styled.aside`
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 10000;
  overflow-y: auto;

  & > article {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
  }

  .close-btn {
    align-self: flex-end;
    color: #999999;
    cursor: pointer;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.43;
  }

  .modal-description {
    font-size: 16px;
    margin: 20px 0 30px;
    line-height: 1.43;
    text-align: center;
  }

  .btn {
    height: 50px;
    font-size: 16px;
    line-height: 26px;
    padding: 13px 30px;
    border: 0;
    border-radius: 8px;
    font-weight: bold;
    background-color: #01a0ff;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 20px;
  }

  &[data-is-mobile='mobile'] {
    max-width: 350px;
  }
`;
