import styled from 'styled-components';
import { TabBarItemProps } from './types/tabBar.types';

const TabBarItem = ({ icon, isActive, label, onClick, className }: TabBarItemProps) => {
  return (
    <StyledWrapper onClick={onClick} className={className}>
      <i>{icon}</i>
      <span data-is-active={isActive}>{label}</span>
    </StyledWrapper>
  );
};

export default TabBarItem;

const StyledWrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  > span {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: -0.44px;
    text-align: center;
    color: #666666;

    &[data-is-active='true'] {
      font-weight: bold;
    }
  }
`;
