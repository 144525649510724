import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import { useCurrentUser } from '../../hook/useCurrentUser';
import CustomerMessageFormContainer from '../userCustomerService/CustomerMessageForm';
import { ChattingProvider } from './chat/context/ChattingContext';
import PromotionPopup from './components/PromotionPopup';
import HeaderDesktop from './desktop/Header.desktop';
import NavBarDesktop from './desktop/NavBar/NavBar.desktop';
import { useCheckHidePage } from './hook/useCheckHideInPage';
import { usePromotionPopup } from './hook/usePromotionPopup';
import HeaderMobile from './mobile/Header.mobile';
import NavBarMobile from './mobile/NavBar/NavBar.mobile';
import { LINKAREER_DOMAIN } from '../../constant/urls';

const HeaderLinkareer = () => {
  const { currentUser } = useCurrentUser();
  const { isHideInPage } = useCheckHidePage();
  const { isOpenPromotionPopup, handleClickClose, handleClickTodayClose, handleRouteUrl } =
    usePromotionPopup();

  const handleClickBanner = () => {
    handleRouteUrl(`https://${LINKAREER_DOMAIN}/prediction/gsat2024-2`);
  };

  return (
    <>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <ChattingProvider>
          <CustomerMessageFormContainer>
            <StyledWrapper data-pathname={isHideInPage ? 'my-cbt' : 'cbt-list'}>
              <div className="mobile-wrapper">
                <HeaderMobile
                  notificationsUnreadCount={currentUser?.notificationUnreadCount}
                  scrapCount={currentUser?.scrapCount}
                  isMyCbt={isHideInPage}
                />
                {!isHideInPage && <NavBarMobile />}
              </div>
              <div className="desktop-wrapper">
                <HeaderDesktop />
                <NavBarDesktop userType={currentUser?.type} />
              </div>
            </StyledWrapper>
            {isOpenPromotionPopup && (
              <PromotionPopup
                id="gsat-promotion-popup"
                onClickBanner={handleClickBanner}
                onClickClose={handleClickClose}
                onClickTodayClose={handleClickTodayClose}
              />
            )}
          </CustomerMessageFormContainer>
        </ChattingProvider>
      </SnackbarProvider>
    </>
  );
};

export default HeaderLinkareer;

const StyledWrapper = styled.header`
  width: 100%;

  @media screen and (min-width: 1024px) {
    position: sticky;
    z-index: 100;
    height: 175px;
    top: -114px;
    margin-bottom: 96px;

    .mobile-wrapper {
      display: none !important;
    }
  }

  @media screen and (max-width: 1023px) {
    height: 206px;
    margin-bottom: 0;

    &[data-pathname='my-cbt'] {
      height: 50px;
      position: sticky;
      top: 0;
      z-index: 100;
    }

    .desktop-wrapper {
      display: none !important;
    }
  }

  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: inherit;
  }

  input,
  button,
  p,
  a,
  span {
    box-sizing: border-box;
  }

  ul,
  ol,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
