import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import Portal from 'src/app.core/linkareer/component/portal/Portal';

interface IFProps {
  onClickClose: () => void;
}

const ChatAppModal: FC<IFProps> = ({ onClickClose }) => {
  const handleClickClose = () => {
    onClickClose();
  };

  return (
    <Portal>
      <StyledWrapper>
        <article>
          <Image
            onClick={handleClickClose}
            src="/images/linkareer/ic-close.svg"
            width={20}
            height={20}
            className="close-icon"
            alt="닫기"
          />
          <Image src="/images/linkareer/chat-app.svg" alt="채팅" width={200} height={135} />
          <div className="chat-modal-title">
            <span>링커리어 앱에서</span> 채팅 기능을 이용해보세요!
          </div>
          <div className="chat-modal-desc">
            채팅방에서 기업의 채용 정보, 합격 스펙 등 <br /> 실시간으로 정보를 공유하세요.
          </div>
          <Link href="https://linkareer.page.link/Lw3m">
            <button className="app-btn">앱으로 이동하기</button>
          </Link>
          <a className="footer" onClick={handleClickClose}>
            다음에 이용할게요.
          </a>
        </article>
      </StyledWrapper>
    </Portal>
  );
};

export default ChatAppModal;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 10000;
  overflow-y: auto;

  article {
    border-radius: 20px;
    padding: 20px 10px 26px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 10px;

    .close-icon {
      width: 14px;
      margin-right: 10px;
      align-self: flex-end;
    }

    .chat-modal-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      letter-spacing: -0.48px;
      margin-top: 5px;
      span {
        color: #01a0ff;
      }
    }

    .chat-modal-desc {
      text-align: center;
      color: #666;
      font-size: 12px;
      line-height: 1.42;
      letter-spacing: -0.36px;
      padding-top: 5px;
    }

    .app-btn {
      width: 280px;
      height: 50px;
      margin: 15px 0;
      color: #ffffff;
      border: 0;
      border-radius: 8px;
      background-color: #01a0ff;
      font-size: 16px;
      font-weight: bold;
      line-height: 50px;
    }

    .footer {
      color: #999;
      font-size: 13px;
    }
  }
`;
