import Link from 'next/link';
import { FC, Fragment } from 'react';
import ExpandMoreBoldIcon from 'src/app.core/linkareer/svgIcon/ExpandMoreBoldIcon';
import styled from 'styled-components';
import ChannelUnavailableModal from '../Modal/ChannelUnavailableModal';
import UserLoginMenu from './UserLoginMenu';
import useUserActionMenu, { menuType } from './hook/useUserActionMenu';
import { CurrentUserQueryResult } from 'src/app.core/linkareer/types/gql.types';

interface UserHeaderLoginActionsProps {
  currentUser?: CurrentUserQueryResult['currentUser'];
  loading?: boolean;
}

const UserHeaderLoginActions: FC<UserHeaderLoginActionsProps> = ({
  currentUser,
  loading = false,
}) => {
  const { userMenuList, channelUnavailable, onCloseModal } = useUserActionMenu(currentUser);

  if (loading) return <></>;
  if (!currentUser) return <UserLoginMenu />;

  return (
    <StyledWrapper>
      <div className="user-box">
        <div className="user-name">{currentUser?.username}</div>
        <ExpandMoreBoldIcon className="expand-more-icon" />
      </div>
      <ul className="user-menu-list">
        {userMenuList.map((menuItem, index) => (
          <Fragment key={`${menuItem.label}-id-${index}`}>
            {menuItem.type === menuType.EXTERNAL_LINK && (
              <li>
                <a
                  key={menuItem.label}
                  href={menuItem.href}
                  className="user-menu-item"
                  {...menuItem.attributes}
                >
                  {menuItem.label}
                </a>
              </li>
            )}
            {menuItem.type === menuType.INTERNAL_LINK && (
              <li>
                <Link
                  key={menuItem.label}
                  href={menuItem.href as string}
                  className="user-menu-item"
                  {...menuItem.attributes}
                >
                  {menuItem.label}
                </Link>
              </li>
            )}
            {menuItem.type === menuType.BUTTON && (
              <li>
                <p className="user-menu-item" onClick={menuItem.onClick}>
                  {menuItem.label}
                </p>
              </li>
            )}
          </Fragment>
        ))}
      </ul>
      {channelUnavailable && <ChannelUnavailableModal onClickCloseModal={onCloseModal} />}
    </StyledWrapper>
  );
};

export default UserHeaderLoginActions;

const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    .user-menu-list {
      display: block;
    }
  }

  .user-box {
    display: flex;
    align-items: center;

    .user-name {
      font-size: 14px;
      max-width: 124px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .expand-more-icon {
      font-size: 10px;
      padding-top: 1px;
      margin-left: 8px;
    }
  }

  .user-menu-list {
    width: 135px;
    padding: 5px 0px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    box-shadow: 3px 3px 3px 0 rgba(0 0 0 / 15%);
    position: absolute;
    z-index: 10;
    top: 30px;
    left: -30px;
    display: none;

    &::before {
      content: '';
      width: 100%;
      height: 30px;
      display: block;
      position: absolute;
      top: -30px;
    }

    & > li {
      width: 100%;
      height: 27.5px;

      :hover {
        background-color: #eeeeee;
      }

      & > a,
      p {
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 15px;
        color: #333333;
        display: block;
      }
    }
  }
`;
