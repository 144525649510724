import { InternalAxiosRequestConfig } from 'axios';
import { globalContext } from '../context';
import cookie from 'cookie';
import Cookies from 'js-cookie';

// 만약 서비스의 백엔드가 쿠키로의 로그인을 지원하지 않으면 사용
export const addAccessTokenToHeader = (req: InternalAxiosRequestConfig) => {
  const ctx = globalContext.getCtx();

  if (ctx?.ctx?.req?.headers?.cookie) {
    req.headers['cookie'] = ctx?.ctx?.req?.headers?.cookie;
  }

  //local 환경에서만 사용함
  const cookies = cookie.parse(ctx?.ctx?.req?.headers?.cookie || '');
  const accessToken = cookies['accessToken'] ?? Cookies.get('accessToken') ?? '';
  if (accessToken) {
    req.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return req;
};
