import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { LINKAREER_API_BASE_URL } from 'src/app.core/linkareer/constant/urls';

export const useChatMessageUnreadCount = () => {
  const requestChatMessageUnreadCount = async () => {
    const { data } = await axios.request({
      method: 'POST',
      url: `${LINKAREER_API_BASE_URL}/graphql`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        query: `
          query gqlChatMessageUnreadCount {
            chatMessageUnreadCount
          }
        `,
      },
      withCredentials: true,
    });
    return data.data;
  };

  const { data } = useQuery(
    ['linkareerChatMessageUnreadCount'],
    async () => requestChatMessageUnreadCount(),
    {
      suspense: false,
    }
  );

  return { chatMessageUnreadCount: data?.chatMessageUnreadCount };
};
