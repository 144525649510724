import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const LinkareerCheckIcon: FC<TSvgProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
      <g data-name="icon /28 / check">
        <path fill="none" d="M0 0h28v28H0z" data-name="사각형 30321"></path>
        <g data-name="그룹 45170">
          <g
            fill="none"
            stroke="#333"
            strokeWidth="2"
            data-name="타원 7"
            transform="translate(2.5 2.5)"
          >
            <circle cx="11.5" cy="11.5" r="11.5" stroke="none"></circle>
            <circle cx="11.5" cy="11.5" r="10.5"></circle>
          </g>
          <path
            fill="#333"
            d="M12.351 19.5a1.04 1.04 0 01-.731-.294L7.8 15.48a1.053 1.053 0 010-1.471 1.1 1.1 0 011.5 0l3.057 3.007 6.343-6.222a1.1 1.1 0 011.5 0 1.023 1.023 0 010 1.471l-7.107 6.941a1.04 1.04 0 01-.742.294z"
            data-name="패스 124"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LinkareerCheckIcon;
