/**
 * @nurseEdu
 * 모바일 환경에서 SideMenu를 띄울 때 배경을 어둡게 하기 위한 컴포넌트입니다.
 */

import { useEffect, useCallback } from 'react';
import styled from 'styled-components';

interface DimProps {
  close: () => void;
}

const Dim = ({ close }: DimProps) => {
  const handleOutsideClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    const scrollLock = (e: TouchEvent) => e.preventDefault();

    document.body.style.overflow = 'hidden';
    document.body.addEventListener('touchmove', scrollLock, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      document.body.removeEventListener('touchmove', scrollLock);
    };
  }, []);

  return <StyledDim onClick={handleOutsideClick} />;
};

export default Dim;

const StyledDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 60%);
`;
