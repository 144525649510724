import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { LINKAREER_BIZ_DOMAIN, LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';

const UserLoginMenu: FC = () => {
  return (
    <StyledWrapper>
      <Link href={`https://${LINKAREER_DOMAIN}/login`} rel="nosublink">
        개인회원
      </Link>
      <a className="corporate" href={`${LINKAREER_BIZ_DOMAIN}/login`} rel="nosublink">
        기업서비스
      </a>
    </StyledWrapper>
  );
};

export default UserLoginMenu;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  a {
    font-size: 14px;
    color: #333;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  .corporate {
    width: 92px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #01a0ff;
    border: solid 1px #01a0ff;
    border-radius: 14px;
  }
`;
