import Link from 'next/link';
import { FC } from 'react';
import { NAV_BAR_MENU_ITEMS } from 'src/app.core/linkareer/constant/navBarMenuItems';
import styled from 'styled-components';

interface SubNavSectionProps {}

const SubNavSection: FC<SubNavSectionProps> = () => {
  return (
    <StyledWrapper>
      <div className="sub-nav-section">
        {NAV_BAR_MENU_ITEMS.map((menuData, index) => {
          return (
            <div className="menu-box" key={`${menuData.label}-${index}`}>
              <Link href={menuData.href}>{menuData.label}</Link>
              <ul>
                {menuData.menuItems?.map((menuItem, index) => {
                  return (
                    <li key={`${menuItem.label}-${index}`}>
                      <Link href={menuItem.href}>{menuItem.label}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </StyledWrapper>
  );
};

export default SubNavSection;

const StyledWrapper = styled.section`
  position: absolute;
  z-index: 100;
  top: 100%;
  width: 100%;
  box-shadow: 0 3px 8px 0 rgba(187, 187, 187, 0.16);
  padding: 24px 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #f4f4f4;

  .sub-nav-section {
    width: 100%;
    max-width: 1100px;
    display: flex;
    > .menu-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #f4f4f4;
      padding-left: 20px;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        border: none;
      }

      & > a {
        height: 21px;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.6px;
        color: #333;
        margin-bottom: 16px;
      }

      & > ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > li {
          width: 100%;
          height: 20px;
          cursor: pointer;

          a {
            height: 20px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.56px;
            line-height: 20px;
            color: #333;
          }
        }
      }
    }
  }
`;
