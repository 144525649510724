import { FC, Fragment } from 'react';
import Logo from 'src/app.component/logo/Logo';
import { useCurrentUser } from 'src/app.core/linkareer/hook/useCurrentUser';
import styled from 'styled-components';
import SearchBarDesktop from './component/SearchBar/SearchBar.desktop';
import UserAction from './component/UserAction/UserAction';

interface IFHeaderDesktopProps {}

const HeaderDesktop: FC<IFHeaderDesktopProps> = () => {
  const { currentUser, isLoading } = useCurrentUser();

  return (
    <Fragment>
      <StyledWrapper>
        <div className="header-container">
          <div className="lt">
            <Logo />
            <SearchBarDesktop />
          </div>
          <UserAction currentUser={currentUser} loading={isLoading} />
        </div>
      </StyledWrapper>
    </Fragment>
  );
};

export default HeaderDesktop;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    height: 114px;
    margin: 0 auto;

    .lt {
      display: flex;
      align-items: center;
      .logo {
        width: 145px;
        height: 34px;
        margin-right: 40px;
        cursor: 'pointer';
      }
    }
  }
`;
