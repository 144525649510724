/**
 * @nurseEdu
 * 널스에듀에서 공통적으로 사용되는 코드입니다.
 */

import styled, { css } from 'styled-components';

type Size = { width: number; height: number };
type Style = { wrapper: Size; svg: Size };
type Device = {
  desktop?: Style;
  tablet?: Style;
};

export const StyledIcon = styled.div<{ $style: Device }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $style: { desktop } }) => desktop && makeStyle(desktop)}

  @media (max-width: 1023px) {
    ${({ $style: { tablet } }) => tablet && makeStyle(tablet)}
  }
`;

const makeStyle = ({ wrapper, svg }: Style) => css`
  width: ${wrapper.width}px;
  height: ${wrapper.height}px;

  > svg {
    width: ${svg.width}px;
    height: ${svg.height}px;
  }
`;
