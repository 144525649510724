import { useSnackbar } from 'notistack';
import { FunctionComponent, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useCreateSuggestion } from 'src/app.core/linkareer/hook/useCreateSuggestion';
import styled from 'styled-components';
import FileUploadDialog, { FileInfo } from './form.fileUpload/FileUploadDialog';
import { DOCUMENT_TO_MIME_TYPES, DOCUMENT_TYPE } from 'src/app.core/linkareer/constant/mimeTypes';
import { CurrentUserQueryResult } from 'src/app.core/linkareer/types/gql.types';

const TEN_MEGABYTES = 10 * 1024 * 1000;

interface CustomerServiceProps {
  currentUser?: CurrentUserQueryResult['currentUser'];
  onRequestClose: () => void;
}

const CustomerService: FunctionComponent<CustomerServiceProps> = ({
  currentUser,
  onRequestClose,
}) => {
  const [isMinimized, setIsMinimizes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [emailInput, setEmailInput] = useState('');
  const [contentText, setContentText] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);

  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const contentInputRef = useRef<HTMLTextAreaElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { createSuggestion } = useCreateSuggestion();

  const handleFileChange = (file: FileInfo[]) => {
    const newFiles = [...files, ...file];

    setFiles(newFiles);
    setAttachmentIds(newFiles.map((file) => file.attachmentID));
  };

  const handleRemoveFileItemButton = useCallback((currentFiles: File[], fileIndex: number) => {
    const newFiles = currentFiles.filter((file, index) => file && index !== fileIndex);
    setFiles([...newFiles]);
  }, []);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (emailInput === '' && emailInputRef.current) {
        enqueueSnackbar('이메일을 입력해주세요.', { variant: 'error' });
        emailInputRef.current.focus();
        return;
      }

      // eslint-disable-next-line prefer-regex-literals
      const emailValidationRegExp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!emailValidationRegExp.test(emailInput) && emailInputRef.current) {
        enqueueSnackbar('잘못된 이메일 형식입니다.', { variant: 'error' });
        emailInputRef.current.focus();
        return;
      }

      if (contentText === '' && contentInputRef.current) {
        enqueueSnackbar('내용을 입력해주세요.', { variant: 'error' });
        contentInputRef.current.focus();
        return;
      }

      setIsLoading(true);

      createSuggestion(
        {
          emailInput,
          contentText,
          attachmentIds,
        },
        {
          onSuccess: () => {
            enqueueSnackbar('소중한 의견에 감사드립니다.', { variant: 'success' });
            setIsLoading(false);
            onRequestClose();
          },
          onError: () => {
            enqueueSnackbar('죄송합니다. 의견을 보내는 중 오류가 발생했습니다.', {
              variant: 'error',
            });
          },
        }
      );
    },
    [emailInput, contentText, files, attachmentIds, onRequestClose]
  );

  const handleRequestClose = useCallback(() => onRequestClose(), [onRequestClose]);

  const handleMinimize = useCallback(() => setIsMinimizes(true), []);
  const handleMaximize = useCallback(() => setIsMinimizes(false), []);

  return (
    <>
      <CustomerServiceArea className={isMinimized ? 'is-hidden' : ''}>
        <CustomerServiceHeader>
          고객/광고 문의
          <img
            className="is-hidden-touch"
            src="/images/linkareer/ic_suggestion_escape.png"
            onClick={handleRequestClose}
            alt=""
          />
          <img
            className="is-hidden-touch"
            src="/images/linkareer/ic_suggestion_minimize_white.png"
            onClick={handleMinimize}
            alt=""
          />
          <img
            className="is-hidden-desktop"
            src="/images/linkareer/ic-arrow-left-white.png"
            onClick={handleRequestClose}
            alt=""
          />
          <span className="is-hidden-desktop" onClick={(e) => handleSubmit(e)}>
            {isLoading ? '보내는중' : '보내기'}
          </span>
        </CustomerServiceHeader>
        <EmailInput
          ref={emailInputRef}
          type="email"
          placeholder="답변 받을 이메일"
          onChange={(e) => setEmailInput(e.currentTarget.value)}
        />
        <ContentTextArea
          ref={contentInputRef}
          placeholder="내용"
          onChange={(e) => setContentText(e.currentTarget.value)}
          filesLength={files.length ? files.length : 0}
          defaultValue={
            currentUser && currentUser.type !== 'NORMAL'
              ? '1. 회사명:\n2. 공고명:\n3. 담당자이름:\n4. 메일주소 및 연락처:\n5. 문의내용:\n'
              : ''
          }
        />
        <FilesContainer className={files.length > 0 ? '' : 'is-hidden'}>
          {files.map((file: File, index: number) => {
            return (
              <FileItemContainer key={index}>
                <FileItem>
                  <FileNameSpan>{file.name}</FileNameSpan>
                </FileItem>
                <RemoveFileItemButton
                  src="/images/linkareer/ic-clear-circle-black.png"
                  onClick={() => handleRemoveFileItemButton(files, index)}
                />
              </FileItemContainer>
            );
          })}
        </FilesContainer>
        <ButtonsContainer>
          <UploadFileButton
            type="button"
            id="uppy-file-upload-trigger-customer-service-file-upload"
            disabled={files.length > 2}
          >
            <img
              src={
                files.length > 2
                  ? '/images/linkareer/ic-clear-black.png'
                  : '/images/linkareer/ic-add-blue.png'
              }
              alt=""
            />
            <span data-isfull={files.length > 2}>첨부파일 추가하기</span>
          </UploadFileButton>
          <SendSuggestionButton
            className="is-hidden-touch"
            type="button"
            onClick={(e: SyntheticEvent) => handleSubmit(e)}
          >
            <span>{isLoading ? '보내는중' : '보내기'}</span>
          </SendSuggestionButton>
        </ButtonsContainer>
      </CustomerServiceArea>
      <MinimizedCustomService className={isMinimized ? '' : 'is-hidden'}>
        <span onClick={handleMaximize}>
          {emailInput || contentText ? '고객문의(작성중)' : '고객문의'}
        </span>
        <img
          className="is-hidden-touch"
          src="/images/linkareer/ic_suggestion_escape.png"
          onClick={handleRequestClose}
          alt=""
        />
        <img
          className="is-hidden-touch"
          src="/images/linkareer/ic_suggestion_maximize_white.png"
          onClick={handleMaximize}
          alt=""
        />
      </MinimizedCustomService>
      <FileUploadDialog
        id="customer-service-file-upload"
        totalFileSizeLimit={TEN_MEGABYTES}
        maxNumberOfFiles={3}
        getFileInfo={handleFileChange}
        allowedFileTypes={DOCUMENT_TO_MIME_TYPES[DOCUMENT_TYPE.IMAGE]}
      />
    </>
  );
};

export default CustomerService;

const CustomerServiceArea = styled.div`
  box-sizing: border-box;

  &.is-hidden {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    color: #000;
    z-index: 1;
    width: 488px;
    height: 534px;
    background-color: #fff;
    border: solid 1px #e5e5e5;
    position: fixed;
    padding: 0;
    margin: 0;
    bottom: 0;
    right: 16px;
    z-index: 100;
  }

  @media screen and (max-width: 1023px) {
    // 1300 MUI dialog z index
    z-index: 501;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    color: #000;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    .is-hidden-desktop {
      display: none !important;
    }
  }

  @media screen and (max-width: 1023px) {
    .is-hidden-touch {
      display: none !important;
    }
  }
`;

const CustomerServiceHeader = styled.div`
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    height: 36px;
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 8px 16px;

    img {
      height: 20px;
      width: 20px;
      float: right;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 48px;
    background-image: linear-gradient(282deg, #005bea, #00c6fb 78%, #00f2fe);
    color: #fff;
    line-height: 48px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    padding: 0;
    margin: 0;

    img {
      height: 24px;
      width: 24px;
      float: left;
      margin-left: 16px;
      margin-top: 13px;
      cursor: pointer;
    }

    span {
      float: right;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      margin-right: 16px;
    }
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 48px;
  margin-bottom: 0;
  padding-left: 16px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  box-sizing: inherit;
`;

interface ContentTextAreaProps {
  filesLength: number;
}

const ContentTextArea = styled.textarea<ContentTextAreaProps>`
  box-sizing: inherit;

  @media screen and (min-width: 1024px) {
    width: 100%;
    height: calc(386px - (56px * ${(props) => props.filesLength} + 8px));
    margin-bottom: ${(props) => (props.filesLength > 0 ? '0px;' : '24px;')};
    padding: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: 0;
    outline: none;
    display: inherit;
    font-size: 14px;
    resize: none;
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    min-height: 300px;
    padding: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: 0;
    outline: none;
    flex: 8;
    font-size: 14px;
    resize: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.02);
    border: solid 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1023px) {
    margin-top: auto;
    width: 100%;
    height: 48px;
    padding-bottom: 8px;
    background-color: #f0f0f0;
  }
`;

const UploadFileButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 14px;
  gap: 6px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #01a0ff;
    &[data-isFull='true'] {
      color: #afafaf;
    }
  }
`;

const SendSuggestionButton = styled.button`
  width: 88px;
  height: 36px !important;
  border-radius: 4px !important;
  background-color: #00a0ff !important;
  position: relative !important;
  right: 16px;
  float: right;
  top: 3px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  line-height: 1.14;
  position: relative;
`;

const FilesContainer = styled.div`
  padding: 8px;

  &.is-hidden {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    background-color: #f0f0f0;
    bottom: 56px;
    flex: 1;
  }
`;

const FileItemContainer = styled.div`
  margin: 8px;
  height: 48px;
  border: solid 1px #ccc;

  @media screen and (min-width: 1024px) {
    width: 456px;
  }

  @media screen and (max-width: 1023px) {
    bottom: 0;
  }
`;

const FileItem = styled.div`
  width: 80%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
`;

const FileNameSpan = styled.span`
  margin: 14px 16px;
  line-height: 3;
`;

const RemoveFileItemButton = styled.img`
  width: 24px;
  height: 24px;
  float: right;
  margin: 10px 18px;
  cursor: pointer;
  vertical-align: middle;
  max-width: 100%;
`;

const MinimizedCustomService = styled.div`
  position: fixed;
  bottom: 0;
  right: 16px;
  width: 232px;
  height: 36px;
  background-color: #666;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;

  &.is-hidden {
    display: none;
  }

  img {
    height: 20px;
    width: 20px;
    float: right;
    margin-left: 8px;
    cursor: pointer;
  }
`;
