import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { uaIsMobile } from '../../module/uaIsMobile';
import CustomerMessageFormContainer from '../userCustomerService/CustomerMessageForm';
import FooterDesktop from './desktop/Footer.desktop';
import FooterMobile from './mobile/Footer.mobile';

const FooterLinkareer = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (navigator) {
      const uaMobile = uaIsMobile(navigator.userAgent);
      setIsMobile(() => uaMobile);
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
      <CustomerMessageFormContainer>
        <FooterMobile />
        <FooterDesktop />
      </CustomerMessageFormContainer>
    </SnackbarProvider>
  );
};

export default FooterLinkareer;
