import Link from "next/link";
import { NURSEEDU_CBT_BASE_URL } from "src/app.core/nurseEdu/constant/nurseEduConfig";
import styled from "styled-components";

interface Prop {
  list: { label: string; path: string; base?: string }[];
}

const ListPopup = ({ list }: Prop) => {
  return (
    <StyledContainer className="cbt-popup">
      <ul>
        {list.map(({ label, path, base = NURSEEDU_CBT_BASE_URL }) => (
          <li key={label}>
            <Link href={`${base}/${path}`}>{label}</Link>
          </li>
        ))}
      </ul>
    </StyledContainer>
  );
};

export default ListPopup;

const StyledContainer = styled.div`
  display: none;

  position: absolute;
  top: 140px;
  z-index: 10;

  min-width: 142px;

  padding-top: 20px;

  ul {
    padding: 4px;

    border-radius: 4px;

    background-color: white;
    box-shadow: 0px 3px 16px 0px #1216321f;
  }

  a {
    display: block;

    padding: 9px 13px;

    font-size: 16px;
    font-weight: 400 !important;

    &:hover {
      background-color: #F5F5F5;
      font-weight: 500 !important;
    }
  }
`;
