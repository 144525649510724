import Image from 'next/image';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { KakaoShareButton } from './KakaoShareButton';
import facebookLink from './index.module';
import Portal from 'src/app.core/linkareer/component/portal/Portal';

enum SharePlatFormType {
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  KAKAOSTORY = 'KAKAOSTORY',
  NAVER = 'NAVER',
  NAVERBAND = 'NAVERBAND',
  TWITTER = 'TWITTER',
  URL = 'URL',
}

interface ShareModalProps {
  title?: string;
  quote?: string;
  shareLink: string;
  onClick?: (platform: SharePlatFormType) => void;
  onClose?: () => void;
}

const ShareModal: FC<ShareModalProps> = ({
  quote = '',
  shareLink,
  title = '공유하기',
  onClose,
  onClick,
}) => {
  const label = useMemo(() => `[링커리어] ${quote}`, [quote]);

  const handleFacebookShareButtonClick = useCallback(() => {
    const shareURL = facebookLink(shareLink, { quote });

    window.open(shareURL);

    if (onClick) {
      onClick(SharePlatFormType.FACEBOOK);
    }
  }, [shareLink, quote]);

  const handleTwitterShareButtonClick = useCallback(() => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURI(
      label
    )}&hashtags=링커리어,linkareer,대학생,공모전,대외활동&url=${encodeURI(shareLink)}`;

    window.open(shareUrl);
    if (onClick) {
      onClick(SharePlatFormType.TWITTER);
    }
  }, [shareLink, quote]);

  const handleNaverShareButtonClick = useCallback(() => {
    const shareURL = `https://share.naver.com/web/shareView?url=${encodeURI(
      shareLink
    )}&title=${encodeURI(label)}`;

    window.open(shareURL);

    if (onClick) {
      onClick(SharePlatFormType.NAVER);
    }
  }, [quote, shareLink]);

  const handleBandShareButtonClick = useCallback(() => {
    const shareURL = `https://band.us/plugin/share?body=${encodeURI(
      `${label}\n${encodeURI(shareLink)}`
    )}`;

    window.open(shareURL);
    if (onClick) {
      onClick(SharePlatFormType.NAVERBAND);
    }
  }, [shareLink, quote]);

  const handleKTShareButtonClick = useCallback(() => {
    if (onClick) {
      onClick(SharePlatFormType.KAKAO);
    }
  }, [shareLink, quote]);

  const handleURLShareButtonClick = useCallback(() => {
    navigator.clipboard.writeText(shareLink);
    if (onClick) {
      onClick(SharePlatFormType.URL);
    }
    enqueueSnackbar('복사되었습니다.');
    onClose && onClose();
  }, [shareLink, quote]);

  return (
    <Portal>
      <StyledWrapper>
        <article>
          <div className="modal-header">
            <div className="header-title">{title}</div>
            <div className="close-btn" onClick={onClose}>
              <Image src="/images/linkareer/ic-close.svg" width={15} height={15} alt="닫기" />
            </div>
          </div>
          <div className="modal-sub-title">
            <div className="share-btn" onClick={handleFacebookShareButtonClick}>
              <Image src="/images/linkareer/facebook.svg" alt="페이스북" width={80} height={80} />
            </div>
            <div className="share-btn" onClick={handleTwitterShareButtonClick}>
              <Image src="/images/linkareer/twitter.svg" alt="트위터" width={80} height={80} />
            </div>
            <div className="share-btn" onClick={handleNaverShareButtonClick}>
              <Image src="/images/linkareer/naver.svg" alt="네이버" width={80} height={80} />
            </div>
            <div className="share-btn" onClick={handleBandShareButtonClick}>
              <Image src="/images/linkareer/band.svg" alt="밴드" width={80} height={80} />
            </div>
            <div className="share-btn">
              <KakaoShareButton
                onClick={handleKTShareButtonClick}
                title="채팅방 입장하기"
                url={shareLink}
              />
            </div>
          </div>
          <div className="bottom-form">
            <div className="input">{shareLink}</div>
            <button className="btn" onClick={handleURLShareButtonClick}>
              복사
            </button>
          </div>
        </article>
      </StyledWrapper>
    </Portal>
  );
};

export default ShareModal;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 10000;
  overflow-y: auto;

  & > article {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    .close-btn {
      align-self: flex-end;
      color: #999999;
      cursor: pointer;
    }
    .modal-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid #eeeeee;
      .header-title {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .modal-title {
      font-size: 24px;
      font-weight: 600;
    }
    .modal-sub-title {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      font-size: 18px;
      margin: 40px 0;
      width: 100%;
      font-weight: 500;
      gap: 40px;
      padding: 0 30px;
      .share-btn {
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
    .modal-description {
      font-size: 16px;
      margin: 20px 0 30px;
      text-align: center;
    }
    .bottom-form {
      display: flex;
      gap: 4px;
      width: 100%;
      margin-bottom: 20px;
      .input {
        width: 100%;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
        color: #999;
        background-color: #eee;
      }
      .btn {
        font-size: 16px;
        padding: 13px 15px;
        flex-shrink: 0;
        background-color: #01a0ff;
        color: #ffffff;
      }
    }

    @media screen and (max-width: 960px) {
      max-width: 350px;
    }
  }
`;
