import { NextSeoProps, DefaultSeoProps } from 'next-seo';
import { TExamsInfo } from 'app.type/examsInfo';

export abstract class BaseSeoConfig {
  protected createSeoTemplate = ({
    title = '',
    description = '',
    openGraph = {
      type: 'website',
      url: '',
      title: '',
      description: '',
      images: [],
    },
    additionalMetaTags,
    additionalLinkTags,
  }: Partial<DefaultSeoProps>): NextSeoProps => ({
    title,
    description,
    openGraph: {
      type: openGraph?.type || 'website',
      url: openGraph?.url || '',
      title: openGraph?.title || title,
      description: openGraph?.description || description,
      images: openGraph?.images || [],
    },
    ...(additionalLinkTags && { additionalLinkTags }),
    ...(additionalMetaTags && { additionalMetaTags }),
  });

  abstract getDefaultSeoMessage(data?: unknown): DefaultSeoProps;
  abstract getExamListSeoMessage(type?: string): NextSeoProps;
  abstract getExamDetailSeoMessage(data: TExamsInfo): NextSeoProps;
  abstract getExamTypeSelectionSeoMessage(examDetailIdx: string): NextSeoProps;
  abstract getCbtOneQuestionTypeSeoMessage(examDetailIdx: string): NextSeoProps;
  abstract getCbtPracticeTypeSeoMessage(examDetailIdx: string): NextSeoProps;
  abstract getExamResultSeoMessage(recordIdx: string): NextSeoProps;
  abstract getExamSolutionSeoMessage(recordIdx: string): NextSeoProps;
  abstract getMyCBTArchiveSeoMessage(): NextSeoProps;
  abstract getMyCBTResultSeoMessage(): NextSeoProps;
  abstract getMyCBTNoteSeoMessage(): NextSeoProps;

  defaultSeo(): DefaultSeoProps {
    const defaultSeoMessage = this.getDefaultSeoMessage();
    return this.createSeoTemplate(defaultSeoMessage);
  }

  examListSeo(type?: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getExamListSeoMessage && this.getExamListSeoMessage(type);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  examDetailSeo(data?: TExamsInfo): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage =
      this.getExamDetailSeoMessage && this.getExamDetailSeoMessage(data as TExamsInfo);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  examTypeSelectionSeo(examDetailIdx: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage =
      this.getExamTypeSelectionSeoMessage && this.getExamTypeSelectionSeoMessage(examDetailIdx);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  cbtOneQuestionTypeSeo(examDetailIdx: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage =
      this.getCbtOneQuestionTypeSeoMessage && this.getCbtOneQuestionTypeSeoMessage(examDetailIdx);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  cbtPracticeTypeSeo(examDetailIdx: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage =
      this.getCbtPracticeTypeSeoMessage && this.getCbtPracticeTypeSeoMessage(examDetailIdx);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  examResultSeo(recordIdx: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getExamResultSeoMessage && this.getExamResultSeoMessage(recordIdx);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  examSolutionSeo(recordIdx: string): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getExamSolutionSeoMessage && this.getExamSolutionSeoMessage(recordIdx);
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  myCBTArchiveSeo(): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getMyCBTArchiveSeoMessage && this.getMyCBTArchiveSeoMessage();
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  myCBTResultSeo(): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getMyCBTResultSeoMessage && this.getMyCBTResultSeoMessage();
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }

  myCBTNoteSeo(): NextSeoProps {
    const defaultSeo = this.getDefaultSeoMessage();
    const seoMessage = this.getMyCBTNoteSeoMessage && this.getMyCBTNoteSeoMessage();
    const reuslt = { ...defaultSeo, ...seoMessage };

    return this.createSeoTemplate(reuslt);
  }
}
