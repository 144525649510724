import MenuIcon from 'src/app.core/linkareer/svgIcon/MenuIcon';
import { TabBarItemProps } from '../types/tabBar.types';
import CommunityIcon from 'src/app.core/linkareer/svgIcon/CommunityIcon';
import HomeIcon from 'src/app.core/linkareer/svgIcon/HomeIcon';
import CalenderIcon from 'src/app.core/linkareer/svgIcon/CalenderIcon';
import ChattingIcon from 'src/app.core/linkareer/svgIcon/ChattingIcon';

interface GetTabBarDataParameter {
  activedTabPathname: string;
}

interface TabBarDataType {
  menu: TabBarItemProps;
  community: TabBarItemProps;
  home: TabBarItemProps;
  calender: TabBarItemProps;
  chatting: TabBarItemProps;
}

const getTabBarData = ({ activedTabPathname }: GetTabBarDataParameter): TabBarDataType => {
  const TAB_BAR_DATA: TabBarDataType = {
    menu: {
      icon: <MenuIcon isActive={activedTabPathname === '/menu'} />,
      isActive: activedTabPathname === '/nav',
      label: '메뉴',
    },
    community: {
      icon: <CommunityIcon isActive={false} />,
      isActive: false,
      label: '커뮤니티',
    },
    home: {
      icon: <HomeIcon isActive={activedTabPathname === '/'} />,
      isActive: activedTabPathname === '/',
      label: '홈',
    },
    calender: {
      icon: <CalenderIcon isActive={activedTabPathname === '/calender'} />,
      isActive: activedTabPathname === '/calender',
      label: '공고달력',
    },
    chatting: {
      icon: <ChattingIcon isActive={false} />,
      isActive: false,
      label: '채팅',
    },
  };

  return TAB_BAR_DATA;
};

export default getTabBarData;
