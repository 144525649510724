import dayjs from 'dayjs';
import nookies from 'nookies';
import { getCookieDomain } from 'src/app.core/linkareer/module/cookie';

export const CHAT_TOOLTIP_COOKIE = 'chat-tooltip';

const chatCookies = () => {
  const setChatCookie = (cookie: string, expires: Date) => {
    nookies.set(null, cookie, Date.now().toString(), {
      expires,
      domain: getCookieDomain(),
      path: '/',
    });
  };

  const addChatTooltipCookie = () => {
    const oneWeek = dayjs().add(7, 'day').toDate();

    setChatCookie(CHAT_TOOLTIP_COOKIE, oneWeek);
  };

  return {
    addChatTooltipCookie,
  };
};

export default chatCookies;
