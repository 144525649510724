import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';

import { IToast, setToastState } from 'recoil/toast';

type UseToastMessageReturn = {
  openToastMessage: (message: IToast['message'], type?: IToast['type']) => void;
  closeToastMessage: () => void;
};

export default function useToastMessage(timeout?: number): UseToastMessageReturn {
  const setToast = useSetRecoilState(setToastState);
  const resetToast = useResetRecoilState(setToastState);

  const openToastMessage = useCallback(
    (message: IToast['message'], type: IToast['type']) => {
      setToast({ message, timeout: timeout ?? 2000, type: type ?? 'error' });
    },
    [setToast, timeout]
  );

  const closeToastMessage = () => {
    resetToast();
  };

  return { openToastMessage, closeToastMessage };
}
