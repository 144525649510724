import { useRouter } from 'next/router';
import { NavBarActiveKey } from 'src/app.core/linkareer/constant/navBarMenuItems';

export const useActiveCategory = () => {
  const { pathname } = useRouter();

  switch (pathname) {
    case '/mycbt/archive': {
      return NavBarActiveKey.CBT_ARCHIVE;
    }
    case '/mycbt/review': {
      return NavBarActiveKey.CBT_REVIEW;
    }
    case '/mycbt/note': {
      return NavBarActiveKey.CBT_NOTE;
    }

    default: {
      return NavBarActiveKey.CBT;
    }
  }
};
