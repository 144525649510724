import Image from 'next/image';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

export interface KakaoShareButtonProps {
  title?: string;
  url?: string;
  onClick: () => void;
}

export const KakaoShareButton: FC<KakaoShareButtonProps> = ({ title = '', url, onClick }) => {
  const handleClick = () => {
    console.log('w4fes');
    const kakao = (window as any).Kakao;

    if (!kakao) {
      return;
    }

    kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: `[링커리어] ${title}`,
        imageUrl: 'https://api.linkareer.com/attachments/548',
        imageWidth: 800,
        imageHeight: 400,
        link: {
          webUrl: url ?? window.location.href,
          mobileWebUrl: url ?? window.location.href,
        },
      },
    });
    onClick();
  };

  useEffect(() => {
    if (!(window as any)?.Kakao?.isInitialized()) {
      (window as any)?.Kakao?.init('171d15c1a52fc8d0eb38550f93c1cbee');
    }
  }, []);

  return (
    <KakaoButton onClick={handleClick}>
      <Image src="/images/linkareer/kakaotalk.svg" alt="카카오톡" width={80} height={80} />
    </KakaoButton>
  );
};

const KakaoButton = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;
