import { useQuery } from '@tanstack/react-query';
import { gqlCurrentUserQuery } from '../gql/linkareer.graphql';
import { linkareerGqlRequest } from '../module/linkareerGqlRequest';
import { CurrentUserQuertVariables, CurrentUserQueryResult } from '../types/gql.types';

export const useCurrentUser = () => {
  const requestCurrentUser = async () => {
    return await linkareerGqlRequest<CurrentUserQueryResult, CurrentUserQuertVariables>({
      query: gqlCurrentUserQuery,
      variables: { notificationType: 'NOTIFICATION' },
    });
  };

  const { data, ...rest } = useQuery(['linkareerCurrentUser'], async () => requestCurrentUser(), {
    suspense: false,
  });

  return { currentUser: data?.currentUser, ...rest };
};
