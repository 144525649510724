import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const CalendarIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="공고 달력"
      {...props}
    >
      <desc>공고 달력</desc>
      <clipPath id="calendarIcon12421">
        <path d="M0 0h16v16H0z" transform="translate(-18887.842 -17602.842)"></path>
      </clipPath>
      <g
        fill="none"
        stroke="#666"
        clipPath="url(#calendarIcon12421)"
        transform="translate(18887.842 17602.842)"
      >
        <g strokeLinecap="round" strokeLinejoin="round">
          <rect
            width="10.835"
            height="10.835"
            rx="2"
            transform="translate(-18885.26 -17599.584)"
          ></rect>
          <path d="M-18877.133-17600.938v2.709M-18882.551-17600.938v2.709M-18881.965-17592.952l1.6 1.6 2.645-2.7"></path>
        </g>
        <path d="M-18885.041-17596.043h10.4"></path>
      </g>
    </svg>
  );
};

export default CalendarIcon;
