import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { CurrentUserQueryResult } from 'src/app.core/linkareer/types/gql.types';
import styled from 'styled-components';
import { useChattingContext } from '../../../chat/context/ChattingContext';
import { useChatMessageUnreadCount } from '../../../chat/hook/useChatMessageUnreadCount';
import useChatTooltipEffect from '../../../chat/hook/useChatTooltipEffect';
import getMaxCount from '../../../module/getMaxCount';
import HeaderChatTooltipDesktop from '../HeaderChatIcon/HeaderChatTooltip.desktop';
import UserHeaderLoginActions from './UserHeaderLoginActions';

interface IFUserActionProps {
  currentUser?: CurrentUserQueryResult['currentUser'];
  loading?: boolean;
}

const getCountMax = (count: number) => {
  if (count > 99) return `99+`;
  return count || '';
};

const UserAction: FC<IFUserActionProps> = ({ currentUser, loading }) => {
  const { chatMessageUnreadCount } = useChatMessageUnreadCount();
  const { isOpen, toggleChatting } = useChattingContext();
  const { isTooltipVisible, setIsTooltipVisible } = useChatTooltipEffect();
  if (loading) return <StyledWrapper />;

  return (
    <StyledWrapper>
      <nav>
        <Link href={`https://${LINKAREER_DOMAIN}/calendar`}>
          <Image width={20} height={20} src="/images/linkareer/ic-calendar.png" alt="공고 달력" />
          공고 달력
        </Link>
        <Link href={`https://${LINKAREER_DOMAIN}/notifications`} rel="nosublink">
          <Image width={20} height={20} src="/images/linkareer/ic-alarm.png" alt="알림" />
          알림
          <p className="count">{getMaxCount(currentUser?.notificationUnreadCount ?? 0)}</p>
        </Link>
        <Link href={`https://${LINKAREER_DOMAIN}/accounts/scrap`} rel="nosublink">
          <Image width={20} height={20} src="/images/linkareer/ic-scrap.png" alt="스크랩" />
          스크랩
          <p className="count">{getMaxCount(currentUser?.scrapCount ?? 0)}</p>
        </Link>
        <div className="chat-wrapper">
          <button type="button" onClick={toggleChatting}>
            <Image
              width={18}
              height={18}
              className="icon"
              src="/images/linkareer/ic-chat.svg"
              alt="채팅"
            />
            <span data-is-active={isOpen}>채팅</span>
            <span className="count">{getCountMax(chatMessageUnreadCount || 0)}</span>
          </button>
          {isTooltipVisible && (
            <HeaderChatTooltipDesktop onClose={() => setIsTooltipVisible(false)} />
          )}
        </div>
      </nav>
      <UserHeaderLoginActions currentUser={currentUser} loading={loading} />
    </StyledWrapper>
  );
};

export default UserAction;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  gap: 16px;
  flex-shrink: 0;

  & > nav {
    display: flex;
    align-items: center;
    gap: 14px;

    & > a {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
    }

    & > .chat-wrapper {
      position: relative;

      & > button {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          font-size: 14px;
          user-select: none;
        }
      }
    }
    .count {
      color: #ef2929;
    }

    &::after {
      content: '';
      width: 1px;
      height: 14px;
      background-color: #ddd;
      margin-left: 2px;
    }
  }
`;
