import Image from 'next/image';
import { FC, useState } from 'react';
import styled from 'styled-components';
import ChatAppModal from '../../chat/component/ChatAppModal';
import { useChatMessageUnreadCount } from '../../chat/hook/useChatMessageUnreadCount';
import NotificationBadge from './NotificationBadge';

const HeaderChatIconMobile: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { chatMessageUnreadCount } = useChatMessageUnreadCount();

  const handleClickClose = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledWrapper>
      <button className="icon-button">
        <NotificationBadge count={chatMessageUnreadCount} />
        <Image
          src="/images/linkareer/ic-chat.svg"
          alt="채팅"
          width={20}
          height={20}
          onClick={() => setIsModalOpen(true)}
        />
      </button>
      {isModalOpen && <ChatAppModal onClickClose={handleClickClose} />}
    </StyledWrapper>
  );
};

export default HeaderChatIconMobile;

const StyledWrapper = styled.div`
  .icon-button {
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    align-items: center;
    position: relative;
  }
`;
