/**
 * @nurseEdu
 * 널스에듀에서 구분선의 용도로 사용한 컴포넌트입니다.
 */

import styled from 'styled-components';

const PageDivider = ({ color = '#D9DCE1' }: { color?: string }) => {
  return (
    <StyledContainer className="divider-container" $color={color}>
      <div />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $color: string }>`
  height: 1px;

  > div {
    position: absolute;
    left: 0;
    width: 100%;
    height: inherit;
    background-color: ${({ $color }) => $color};
  }
`;

export default PageDivider;
