export const INTRODUCE_LINKAREER = '//medium.com/specupad/introduce-linkareer-c3e285beb0';
export const SPECUPAD_JOBPOSTS = '//specupad.com/careers/#jobPosts';
export const AD_CONTACT =
  '//docs.google.com/forms/d/e/1FAIpQLSeshiJ7LduGo4VxUQ3gQnz-_PyUoRNc7CC1BClRtu17VChmjw/viewform';
export const TIMESPREAD_LECTURES = '//timespread.co.kr/lectures';
export const ACTIVITY_BOT = '//pf.kakao.com/_jXHxoT';
export const CONTEST_BOT = '//pf.kakao.com/_JnqAxb';
export const RECRUIT_BOT = '//pf.kakao.com/_IxeGdxb';
export const COVERLETTER_BOT = '//pf.kakao.com/_MGgexb';
export const LINKAREER_BLOG = '//blog.naver.com/linkareer1004';
export const LINKAREER_POST = '//post.naver.com/linkareer1004?isHome=1';
export const FB_UNIVWHAT = '//www.facebook.com/univwhat';
export const FB_CONTESTWHAT = '//www.facebook.com/contestWhat';
export const SPECUP_CAFE = '//cafe.naver.com/specup';
export const MEDIA_INTRODUCTION =
  'https://s3.ap-northeast-2.amazonaws.com/media.linkareer.com/business/linkareer_2005.pdf';
export const FINANCIAL_REPORTS = 'https://timespread.notion.site/100ab4d5e55f4d89958fdb50653e5840';

export const LINKAREER_API_BASE_URL =
  process.env.CBT_ENV === 'production'
    ? 'https://api.linkareer.com'
    : 'https://gateway-api.lnkr.cc';

export const LINKAREER_DOMAIN = process.env.CBT_ENV === 'production' ? 'linkareer.com' : 'lnkr.cc';
export const LINKAREER_COMMUNITY_DOMAIN =
  process.env.CBT_ENV === 'production' ? 'community.linkareer.com' : 'community.lnkr.cc';
export const LINKAREER_BIZ_DOMAIN =
  process.env.CBT_ENV === 'production' ? 'https://biz.linkareer.com' : 'https://biz.lnkr.cc';
export const LINKAREER_CBT_DOMAIN =
  process.env.CBT_ENV === 'production' ? 'https://cbt.linkareer.com' : 'https://cbt.lnkr.cc';
export const CBT_COMMUNITY_DOMAIN =
  process.env.CBT_ENV === 'production'
    ? 'https://cbt-community.linkareer.com'
    : 'https://cbt-community.lnkr.cc';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.linkareer.linkareer';
export const APP_STORE_URL =
  'https://apps.apple.com/kr/app/%EB%A7%81%EC%BB%A4%EB%A6%AC%EC%96%B4/id1526086787';

export const LINKAREER_CHAT_URL =
  process.env.CBT_ENV === 'production' ? 'https://chat.linkareer.com' : 'https://chat.lnkr.cc';
export const SERVICE_FEEDBACK_GOOGLE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfiV7XHrdJvuv5Mcwme-jwgzbsAE0tymiBpXl9vv9-gzOKt6Q/viewform?usp=sf_link';
