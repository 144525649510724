import { useRecoilState } from 'recoil';

import { modalRootSelector } from 'recoil/modalRoot';

export default function ModalRoot() {
  const [modals, setModals] = useRecoilState(modalRootSelector);
  return (
    <div>
      {modals.map((modal, index) => {
        const { Component, key } = modal;

        return <Component key={index} />;
      })}
    </div>
  );
}
