import Link from 'next/link';
import { FC } from 'react';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import CalendarIcon from 'src/app.core/linkareer/svgIcon/CalendarIcon';
import styled from 'styled-components';

const HeaderCalendarIcon: FC = () => {
  return (
    <StyledWrapper>
      <Link href={`https://${LINKAREER_DOMAIN}/calendar`} className="icon-button">
        <CalendarIcon className="calendar-icon" />
      </Link>
    </StyledWrapper>
  );
};

export default HeaderCalendarIcon;

const StyledWrapper = styled.div`
  .icon-button {
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    align-items: center;

    .calendar-icon {
      width: 20px;
      height: 20px;
      & g {
        stroke: #fff;
      }
    }
  }
`;
