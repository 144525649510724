import { useRouter } from 'next/router';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { useCurrentUser } from 'src/app.core/linkareer/hook/useCurrentUser';
import { TChattingRequestTypes } from '../type/chattingRequest.type';

type TChattingRequestEventListener = <T extends TChattingRequestTypes>(
  event: MessageEvent<T>
) => void;

type TUseChattingRequestMessageHandlerOptions = {
  accessToken?: string;
  onRequestClose: () => void;
  onRequestSnsShare: (url: string) => void;
};

const useChattingRequestMessageHandler = (options: TUseChattingRequestMessageHandlerOptions) => {
  const { accessToken, onRequestClose, onRequestSnsShare } = options;

  const router = useRouter();

  useEffect(() => {
    const chattingRequestEventListener: TChattingRequestEventListener = async (event) => {
      switch (event.data.type) {
        case 'requestChatClose': {
          onRequestClose();
          return;
        }
        case 'requestChatLogin': {
          router.push(`https://${LINKAREER_DOMAIN}/login`);
          return;
        }
        case 'requestChatSnsShare': {
          onRequestSnsShare(event.data.data.link);
          return;
        }
        case 'requestChatCopyText': {
          navigator.clipboard.writeText(event.data.data.copyText);
          enqueueSnackbar('복사되었습니다.');
          return;
        }
        case 'requestChatClickLink': {
          window.open(event.data.data.link);
          return;
        }
        case 'requestChatToken': {
          if (accessToken) {
            event.source?.postMessage(
              {
                type: 'reportChatToken',
                data: {
                  token: accessToken,
                },
              },
              {
                targetOrigin: event.origin,
              }
            );
          }
          return;
        }
      }
    };

    window.addEventListener('message', chattingRequestEventListener);

    return () => {
      window.removeEventListener('message', chattingRequestEventListener);
    };
  }, [onRequestClose, onRequestSnsShare, router, accessToken]);
};

export default useChattingRequestMessageHandler;
