/**
 * @nurseEdu
 * Header 우측 장바구니 아이콘에 장바구니 안의 상품 개수를 표시하기 위해 사용하는 코드입니다.
 */

import { useEffect, useState } from 'react';
import useShopbyClient from './useShopbyClient';

interface Props {
  enabled?: boolean;
}

interface CartCountResDTO {
  count: number;
}

const useCartCount = ({ enabled = true }: Props) => {
  const [cartCount, setCartCount] = useState<CartCountResDTO>({ count: 0 });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { shopbyClient } = useShopbyClient();

  const fetchCartCount = async () => {
    setIsLoading(true);
    try {
      const uri = `/cart/count`;
      const { data } = await shopbyClient.get<CartCountResDTO>(uri);
      setCartCount(data);
    } catch (e) {
      console.error(e);
      setError('API 에러');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!enabled) {
      return;
    }

    fetchCartCount();
  }, [enabled]);

  return {
    cartCount,
    isLoading,
    error,
  };
};

export default useCartCount;
