import { useEffect } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';

import 'nprogress/nprogress.css';

export default function LoadingBoundary({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const start = () => {
      NProgress.start();
      NProgress.inc(0.25);
    };
    const end = () => {
      NProgress.done();
    };

    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  return <>{children}</>;
}
