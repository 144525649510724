import { atom, selector } from 'recoil';

export const hostState = atom({
  key: 'hostState',
  default: '',
});

export const setHostState = selector({
  key: 'setHostState',
  get: ({ get }) => get(hostState),
  set: ({ set }, newValue) => {
    set(hostState, newValue);
  },
});
