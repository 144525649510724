import React, { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const RecruitRegisterIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      data-name="icon/20/registration"
      viewBox="0 0 20 20"
      {...props}
    >
      <path fill="none" d="M0 0h20v20H0z" data-name="사각형 12215"></path>
      <g fill="none" data-name="그룹 36704">
        <g strokeLinecap="round" data-name="패스 8416">
          <path d="M5.426 1.404h7.65l4.315 4.351v10.184a2.945 2.945 0 01-2.815 3.06h-9.15a2.945 2.945 0 01-2.815-3.06V4.464a2.945 2.945 0 012.815-3.06z"></path>
          <path
            fill="#333"
            d="M5.426 2.604c-.89 0-1.615.834-1.615 1.86v11.475c0 1.025.725 1.86 1.615 1.86h9.15c.89 0 1.615-.835 1.615-1.86v-9.69l-3.615-3.645h-7.15m0-1.2h7.65l4.315 4.35v10.185c0 1.69-1.26 3.06-2.816 3.06H5.426c-1.555 0-2.815-1.37-2.815-3.06V4.464c0-1.69 1.26-3.06 2.815-3.06z"
          ></path>
        </g>
        <path
          stroke="#333"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M12.707 2.142v4.4h4.291"
          data-name="패스 8417"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M8.834 9.432h4.279"
          data-name="패스 9031"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M6.889 9.431h.354"
          data-name="패스 9346"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M6.888 11.703h.354"
          data-name="패스 9347"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M6.889 14.003h.354"
          data-name="패스 9348"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M8.834 11.703h4.279"
          data-name="패스 14814"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M8.834 14.004h4.279"
          data-name="패스 14815"
        ></path>
      </g>
      <g fill="#fff" data-name="패스 14812">
        <path d="M15 19.4a4.405 4.405 0 01-4.4-4.4c0-2.426 1.974-4.4 4.4-4.4s4.4 1.974 4.4 4.4-1.974 4.4-4.4 4.4z"></path>
        <path
          fill="#333"
          d="M15 11.2a3.804 3.804 0 00-3.8 3.8c0 2.095 1.705 3.8 3.8 3.8 2.095 0 3.8-1.705 3.8-3.8 0-2.095-1.705-3.8-3.8-3.8m0-1.2a5 5 0 110 10 5 5 0 010-10z"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.342 15.31l.825.832 2.489-2.5"
        data-name="패스 14824"
      ></path>
    </svg>
  );
};

export default RecruitRegisterIcon;
