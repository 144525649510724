import { TChattingQueryParamKeys } from '../type/chattingQueryParams'

export type TQueryParamsGeneratorOption<T> = {
  paramKey: T
  paramValue: string
}

/**
 * @param paramList 파라미터 리스트
 * @example [{ paramKey: 'accessToken', paramValue: '123' }] => `accessToken=123`
 * @returns 쿼리스트링. 앞에 ?는 붙지 않는다.
 */
const chattingQueryParamsGenerator = (
  paramList: TQueryParamsGeneratorOption<TChattingQueryParamKeys>[],
): string => {
  const result = paramList.map(({ paramKey, paramValue }) => {
    return `${paramKey}=${paramValue}`
  })

  return result.join('&')
}

export default chattingQueryParamsGenerator
