import { useEffect } from 'react'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { NextRouter } from 'next/router'
import {
  CHAT_OPEN_TYPE,
  CHAT_OPEN_TYPE_QUERY_KEY,
} from '../type/chattingQueryParams'
import {
  TChattingReportOpenRoom,
  TChattingReportTypes,
} from '../type/chattingReport.type'

const getOpenChattingOption = (
  query: NextParsedUrlQuery,
): TChattingReportOpenRoom | undefined => {
  if (!query.chatRoomId) return undefined

  const option: TChattingReportOpenRoom = {
    type: 'reportChatOpenChatRoom',
    data: { chatRoomId: query.chatRoomId as string },
  }
  if (query.chatMessageId) {
    option.data.chatMessageId = query.chatMessageId as string
  }

  return option
}

const useParseChatQueryEffect = (
  router: NextRouter,
  isLoaded: boolean,
  openChatting: (reportOption?: TChattingReportTypes) => void,
) => {
  useEffect(() => {
    const chatOpenType = router.query[CHAT_OPEN_TYPE_QUERY_KEY]
    if (!isLoaded || !chatOpenType) {
      return
    }

    const openChattingOption = getOpenChattingOption(router.query)
    setTimeout(() => {
      if (chatOpenType === CHAT_OPEN_TYPE.ONLY_ROOM) {
        openChattingOption && openChatting(openChattingOption)
      } else {
        openChatting(openChattingOption)
      }
    }, 1000)

    const query = { ...router.query }
    delete query[CHAT_OPEN_TYPE_QUERY_KEY]
    delete query.chatRoomId
    delete query.chatMessageId

    router.replace({ query }, undefined, { shallow: true })
  }, [isLoaded, router.query])
}

export default useParseChatQueryEffect
