// 사용자 조회 api 타입

export enum UserType {
  MANAGER = 'MANAGER',
  MANAGER_REJECTED = 'MANAGER_REJECTED',
  MANAGER_UNVERIFIED = 'MANAGER_UNVERIFIED',
  MANAGER_VERIFIED = 'MANAGER_VERIFIED',
  NORMAL = 'NORMAL',
  WRITER = 'WRITER',
}

export type CurrentUserQuertVariables = {
  notificationType: 'NOTIFICATION';
};

export type CurrentUserQueryResult = {
  currentUser: {
    id: string;
    type: UserType;
    username?: string;
    scrapCount?: number;
    notificationUnreadCount?: number;
    channel?: {
      id: string;
      url: string;
      deactivatedAt?: string | null;
    };
    tokenSet?: {
      accessToken?: string;
    };
  } | null;
};

// 고객 문의 api 타입
export type CreateSuggestionMutationVariables = {
  input: {
    email: string;
    content: string;
    attachmentIds: Array<string>;
    deviceInfo: 'MOBILE' | 'PC';
  };
};

export type CreateSuggestionMutationResult = {
  suggestion: {
    email: string;
    content: string;
  };
};

// 첨부파일 prepare 업로드 api 타입
export type AttachmentPrepareUploadMutationVariables = {
  name: string;
  size: number;
  type: string;
  path?: string;
};

export type AttachmentPrepareUploadMutationResult = {
  attachmentPrepareUpload: {
    attachment: {
      id: string;
      url: string;
    };
    signedURL: string;
  };
};

// 첨부파일 complete 업로드 api 타입
export type AttachmentCompleteUploadMutationVariables = {
  attachmentID: string;
};

export type AttachmentCompleteUploadMutationResult = {
  attachmentCompleteUpload: {
    attachment: {
      id: string;
      url: string;
      name: string;
      type: string;
      size: string;
    };
  };
};

// 자소서 건수 조회 api 타입
export type CoverLetterCountQueryResult = {
  coverLetterTotalCount?: number;
};
