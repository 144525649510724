import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import getMaxCount from '../../module/getMaxCount';

interface IFProps {
  count?: number;
  children?: ReactNode;
}

const NotificationBadge: FC<IFProps> = ({ count, children }) => {
  if (!count || count <= 0) {
    return null;
  }

  return (
    <StyledWrapper>
      {getMaxCount(count)}
      {children}
    </StyledWrapper>
  );
};

export default NotificationBadge;

const StyledWrapper = styled.span`
  min-width: 15px;
  height: 15px;
  border-radius: 7.5px;
  color: #ffffff;
  background-color: #ff0066;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  display: block;
  padding: 0 4px !important;
  position: absolute;
  top: -0.5px;
  right: -2px;
`;
