import Link, { LinkProps } from 'next/link';
import { FC } from 'react';
import RecruitRegisterIcon from 'src/app.core/linkareer/svgIcon/RecruitRegisterIcon';
import styled from 'styled-components';

interface IFProps extends Omit<LinkProps, 'href'> {
  activityHref: string;
  activityLabel: string;
}

const NavBarLinkWithIcon: FC<IFProps> = ({ activityHref, activityLabel, ...restLinkProps }) => {
  return (
    <StyledWrapper>
      <Link href={activityHref} {...restLinkProps}>
        <RecruitRegisterIcon height={20} width={20} />
        {activityLabel}
      </Link>
    </StyledWrapper>
  );
};

export default NavBarLinkWithIcon;

const StyledWrapper = styled.div`
  height: 24px;
  border: 1px solid #ffffff;
  border-radius: 19px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 1px;
    height: 12px;
    background-color: #cccccc;
    margin: 0 15px;
  }

  a {
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.64px;
    text-align: left;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;
