/**
 * @nurseEdu
 * 널스에듀 모바일 환경에서 검색창을 위한 코드입니다.
 */

import { useRouter } from 'next/router';
import { NURSEEDU_BASE_URL } from 'src/app.core/nurseEdu/constant/nurseEduConfig';
import styled from 'styled-components';

interface Props {
  keywords?: string;
  handleInputChange: any;
  handleSearchInputOpen?: () => void;
}

const MobileSearchInput = ({
  keywords,
  handleInputChange,
  handleSearchInputOpen,
}: Props) => {
  const router = useRouter();

  const handleSearch = async () => {
    if (!keywords?.trim()) {
      alert('1글자 이상 검색해주세요.');
      return;
    }

    const searchUrl = `${NURSEEDU_BASE_URL}/search?keywords=${keywords}`;
    router.push(searchUrl, undefined, { shallow: true });
  };

  return (
    <StyledContainer>
      <div className="search">
        <svg
          className="ic-magnifier-search"
          width={20}
          height={20}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleSearch}
        >
          <path
            d="M5.5 0C8.53757 0 11 2.46243 11 5.5C11 6.83879 10.5217 8.06586 9.72656 9.01962L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.68 14.0271 13.4106 14.0464 13.2157 13.9114L13.1464 13.8536L9.01962 9.72656C8.06586 10.5217 6.83879 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0ZM5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1Z"
            fill="#767676"
          />
        </svg>
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          value={keywords}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              handleSearch();
            }
          }}
        />
        <IconWrapper onClick={handleSearchInputOpen}>
          <svg
            width={20}
            height={20}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.89705 4.55379L4.96967 4.46967C5.23594 4.2034 5.6526 4.1792 5.94621 4.39705L6.03033 4.46967L12.5 10.939L18.9697 4.46967C19.2626 4.17678 19.7374 4.17678 20.0303 4.46967C20.3232 4.76256 20.3232 5.23744 20.0303 5.53033L13.561 12L20.0303 18.4697C20.2966 18.7359 20.3208 19.1526 20.1029 19.4462L20.0303 19.5303C19.7641 19.7966 19.3474 19.8208 19.0538 19.6029L18.9697 19.5303L12.5 13.061L6.03033 19.5303C5.73744 19.8232 5.26256 19.8232 4.96967 19.5303C4.67678 19.2374 4.67678 18.7626 4.96967 18.4697L11.439 12L4.96967 5.53033C4.7034 5.26406 4.6792 4.8474 4.89705 4.55379L4.96967 4.46967L4.89705 4.55379Z"
              fill={'#222'}
            />
          </svg>
        </IconWrapper>
      </div>
    </StyledContainer>
  );
};

export default MobileSearchInput;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-inline: 20px;
  background-color: white;

  .search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
      height: 36px;
      margin-right: 8px;
      padding-inline: 40px;
      font-weight: 400;
      font-size: 15px;
      font-style: inherit;
      line-height: 150%;
      letter-spacing: -0.01em;
      border: 1px solid #d9d9d9;
      border-radius: 18px;

      &:focus {
        border: 1px solid #2768ff;
      }

      &::placeholder {
        color: #767676;
      }
    }

    .ic-magnifier-search {
      position: absolute;
      left: 16px;
      width: 14px;
      height: 14px;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const IconWrapper = styled.div``;
