import Link from 'next/link';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { NURSEEDU_BASE_URL } from '../../constant/nurseEduConfig';

const FooterNurseEdu = () => {
  const [isBusinessInfoOpen, setIsBusinessInfoOpen] = useState(false);

  return (
    <div>
      <StyledDivider className="divider-container">
        <div />
      </StyledDivider>
      <StyledContainer>
        <div id="bl-footer">
          <div className="logo-wrapper">
            <StyledLogo>널스에듀</StyledLogo>
          </div>
          <div className="bl-description">
            <nav>
              {/* <Link href="/">회사소개</Link> */}
              {/* <Link href="/">제휴문의</Link> */}
              <Link href={`${NURSEEDU_BASE_URL}/terms?termsType=USE`}>
                이용약관
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/terms?termsType=PI_PROCESS`}>
                개인정보처리방침
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/help`}>고객센터</Link>
            </nav>
            <div>
              <button
                className="btn-info"
                onClick={() => setIsBusinessInfoOpen(!isBusinessInfoOpen)}
              >
                널스에듀 사업자 정보
                <span className="ic-caret-wrapper">
                  {isBusinessInfoOpen ? (
                    <svg
                      width={12}
                      height={8}
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.146279 7.10223C-0.0493307 6.90731 -0.0498943 6.59073 0.14502 6.39512L5.61 0.910639C5.82495 0.694926 6.17426 0.694927 6.3892 0.91064L11.8542 6.39512C12.0491 6.59073 12.0485 6.90732 11.8529 7.10223C11.6573 7.29714 11.3407 7.29658 11.1458 7.10097L5.9996 1.93639L0.853385 7.10097C0.658471 7.29658 0.341889 7.29714 0.146279 7.10223Z"
                        fill="#767676"
                      />
                    </svg>
                  ) : (
                    <svg
                      width={12}
                      height={8}
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8537 0.895818C12.0493 1.09073 12.0499 1.40731 11.855 1.60292L6.39 7.08741C6.17505 7.30312 5.82574 7.30312 5.6108 7.08741L0.145817 1.60292C-0.0490969 1.40731 -0.0485323 1.09073 0.147077 0.895817C0.342687 0.700903 0.659269 0.701467 0.854182 0.897077L6.0004 6.06166L11.1466 0.897077C11.3415 0.701468 11.6581 0.700904 11.8537 0.895818Z"
                        fill="#767676"
                      />
                    </svg>
                  )}
                </span>
              </button>
              <BusinessInfoContainer $isOpen={isBusinessInfoOpen}>
                <p>
                  사업자 등록번호 : 351-87-00156 <span>정보확인</span>
                </p>
                <p>통신판매업 신고 : 제 2020-서울강동-0203호</p>
                <p>서울특별시 강동구 양재대로 98길 16, 201호 (둔촌동)</p>
                <p className="representative-infos">
                  <span>(주) 홍지문</span>
                  <span>대표이사 : 황현식</span>
                  <span>개인정보관리 책임자: 김윤성</span>
                </p>
                <p>nurse.edu.contact@gmail.com</p>
              </BusinessInfoContainer>
              <p id="help-center">
                널스에듀 고객센터: 070-7688-3655
                <br />
                (월-금 10 ~ 19시 운영, 평일 13시 ~ 14시 & 주말 제외)
              </p>
            </div>
          </div>
        </div>
        <div id="copyright">
          COPYRIGHT &copy; 2016 주식회사 홍지문. ALL RIGHTS RESERVED.
        </div>
      </StyledContainer>
    </div>
  );
};

export default FooterNurseEdu;

const StyledContainer = styled.footer`
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;

  * {
    font-family: 'Pretendard Variable', Pretendard, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #bl-footer {
    display: flex;
    gap: 100px;
    padding: 30px 0 20px;

    .bl-description {
      display: flex;
      flex-direction: column;
      gap: 16px;

      nav {
        display: flex;
        gap: 20px;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.01em;

        a {
          color: #767676;
        }

        @media (max-width: 768px) {
          gap: 12px;
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 12px;

        button {
          display: flex;
          gap: 7px;
          justify-content: center;
          align-items: center;
          color: #767676;
          font-weight: 500;
          font-size: 12px;
          line-height: 130%;
          letter-spacing: -0.01em;
          border: none;
          background-color: transparent;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
          }

          @media (min-width: 769px) {
            display: none;
          }
        }

        p {
          color: #767676;
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
          letter-spacing: -0.01em;

          @media (max-width: 768px) {
            text-align: center;
          }
        }
      }

      @media (max-width: 768px) {
        align-items: center;
      }
    }

    @media (max-width: 768px) {
      gap: unset;
      justify-content: center;
      padding: 0;

      .logo-wrapper {
        display: none;
      }
    }
  }

  #help-center {
    color: #434343;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.01em;
  }

  #copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    color: #767676;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.01em;
  }

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;

const BusinessInfoContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 3px;

  .representative-infos {
    display: flex;
    gap: 6px;

    span:not(:first-child)::before {
      content: '|';
      margin-right: 6px;
    }
  }

  ${({ $isOpen }) => css`
    @media (max-width: 768px) {
      display: ${$isOpen ? 'flex' : 'none'};
    }
  `}
`;

const StyledLogo = styled.h1`
  color: #2f3982;
  font-weight: 700;
  font-size: 24px;
  line-height: 27.6px;
  letter-spacing: -3%;
`;

const StyledDivider = styled.div<{ $color?: string }>`
  height: 1px;

  > div {
    position: absolute;
    left: 0;
    width: 100%;
    height: inherit;
    background-color: ${({ $color = '#D9DCE1' }) => $color};
  }
`;
